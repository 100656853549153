// auth.service.ts
import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, of} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {catchError, map} from "rxjs/operators";
import {User} from "../models/user.model";
@Injectable({
    providedIn: 'root'
})
export class CsrfTokenService {

    constructor(){}

    decodeToken(token){
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    }

    compareExp(token): boolean{
        return !(token.exp < (Date.now()/1000).toString());
    }

}
