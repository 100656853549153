<mat-card class="custom-elevation">
    <mat-card-title> {{ 'detailsPage.history.serviceHistory' | translate }}</mat-card-title>
    <mat-card-content *ngIf="equipmentHistory.length == 0">{{ 'detailsPage.noEntries' | translate }}</mat-card-content>
    <table mat-table [dataSource]="dataSourceHistory" *ngIf="equipmentHistory.length > 0">

        <ng-container matColumnDef="orderNumber">
            <th mat-header-cell *matHeaderCellDef> {{ 'detailsPage.history.orderNumber' | translate }}</th>
            <td mat-cell *matCellDef="let element"> {{element.orderNumber}} </td>
        </ng-container>

        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef> {{ 'detailsPage.history.date' | translate }}</th>
            <td mat-cell *matCellDef="let element"> {{element.date}} </td>
        </ng-container>

        <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <mat-icon *ngIf="element.type==1" aria-hidden="false"
                          matTooltip="{{ 'hoverInfo.serviceCall' | translate }}">build
                </mat-icon>
                <mat-icon *ngIf="element.type==2" aria-hidden="false"
                          matTooltip="{{ 'hoverInfo.sparePartsSupply' | translate }}">shopping_cart
                </mat-icon>
                <mat-icon *ngIf="element.type==3" aria-hidden="false" class="blocked-icon"
                          matTooltip="{{ 'hoverInfo.blocked' | translate }}">notification_important
                </mat-icon>
            </td>
        </ng-container>

        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> {{ 'detailsPage.history.description' | translate }}</th>
            <td mat-cell *matCellDef="let element">
                {{element.description}}
            </td>
        </ng-container>

        <ng-container matColumnDef="hasOrderAttachment">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <div style="display: flex; align-items: center;">
                    <div *ngIf="element.hasOrderAttachment">
                        <vi-pdf-menu [systemDetails]="systemDetails" [historyItem]="element"></vi-pdf-menu>
                    </div>
                    <div *ngIf="element.type==3 && element.hasReportAttachment">
                        <vi-pdf-menu [systemDetails]="systemDetails" [historyItem]="element"></vi-pdf-menu>
                    </div>
                    <div *ngIf="userInformationService.isVi && element.longtext != undefined">
                        <mat-icon class="longtext-icon" (click)="openLongtextDialog(element.longtext)"
                                  matTooltip="{{ 'hoverInfo.notificationLongText' | translate }}">sticky_note_2
                        </mat-icon>
                    </div>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="statusDisposition">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="element.statusDisposition != ''">{{element.statusDisposition}}</span>
                <span *ngIf="element.type === '3' && element.statusNotification != ''">{{ 'notifPhase.' + element.statusNotification | translate }}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="partnerPortal">

            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <div
                    *ngIf="!this.userInformationService.isEK() && this.userInformationService.partnerPortalExists() && element.type!=='3'">
                    <a class="icon-column"
                       href="{{partnerPortalUrl}}{{element.orderNumber}}"
                       target="_blank" rel="noopener noreferrer">
                        <img
                            matTooltip="{{ 'buttons.partnerPortalService' | translate }}"
                            style="vertical-align: bottom;"
                            fxFlex="30px" width="30px" height="30px"
                            src="assets/images/marktpartner-portal-app-icon.svg" viewBox="0 0 275 60"
                            alt="Partner Portal">
                    </a>
                </div>
            </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsHistory"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsHistory;"></tr>
    </table>
    <mat-paginator *ngIf="equipmentHistory.length > 10" [pageSizeOptions]="[10, this.entries]"
                   showFirstLastButtons></mat-paginator>
</mat-card>
