<mat-card class="custom-elevation">
    <mat-card-title> {{ 'detailsPage.contract.contract' | translate }}</mat-card-title>
    <mat-card-content
        *ngIf="equipmentContracts.length == 0">{{ 'detailsPage.noEntries' | translate }}</mat-card-content>
    <table mat-table [dataSource]="dataSourceContract" *ngIf="equipmentContracts.length > 0">
        noEntries
        <ng-container matColumnDef="contractNumber">
            <th mat-header-cell *matHeaderCellDef> {{ 'detailsPage.contract.contractNumber' | translate }}</th>
            <td mat-cell *matCellDef="let element"> {{element.contractNumber}} </td>
        </ng-container>

        <ng-container matColumnDef="posNumber">
            <th mat-header-cell *matHeaderCellDef> {{ 'detailsPage.contract.position' | translate }}</th>
            <td mat-cell *matCellDef="let element"> {{element.posNumber}} </td>
        </ng-container>

        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> {{ 'detailsPage.contract.description' | translate }}</th>
            <td mat-cell *matCellDef="let element"> {{element.description}} </td>
        </ng-container>

        <ng-container matColumnDef="runTimeAndEqui">
            <th mat-header-cell *matHeaderCellDef> {{ 'detailsPage.contract.term' | translate }}</th>
            <td mat-cell *matCellDef="let element"> <ng-container *ngIf="!element.runTimeAndEqui.includes('-')">{{ 'termPlaceholderViGuideProTrial' | translate }} {{element.runTimeAndEqui}}</ng-container><ng-container *ngIf="element.runTimeAndEqui.includes('-')">{{element.runTimeAndEqui}}</ng-container></td>
        </ng-container>

        <ng-container matColumnDef="hasAttachment">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="element.hasAttachments">
                    <vi-contract-pdf-menu [systemDetails]="systemDetails"
                                          [contractItem]="element"></vi-contract-pdf-menu>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="partnerPortal">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="!this.userInformationService.isEK() && this.userInformationService.partnerPortalExists()">
                    <a class="icon-column"
                       href="{{partnerPortalUrl}}?orderData.contractNumber={{element.contractNumber}}"
                       target="_blank" rel="noopener noreferrer">
                        <img
                            matTooltip="{{ 'buttons.partnerPortalContract' | translate }}"
                            style="vertical-align: bottom;"
                            fxFlex="30px" width="30px" height="30px"
                            src="assets/images/marktpartner-portal-app-icon.svg" viewBox="0 0 275 60" alt="Partner Portal">
                    </a>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsContract"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsContract;"></tr>
    </table>
    <mat-paginator *ngIf="equipmentContracts.length > 10" [pageSizeOptions]="[10, this.entries]"
                   showFirstLastButtons></mat-paginator>
    <div class="next-maint" *ngIf="systemDetails.nextMaintenance.length > 0">
        <div
            style="margin-bottom: 8px; font-weight: bold;">{{ 'detailsPage.contract.nextMaintenance' | translate }}</div>
        <div fxLayout="row wrap">
            <div>
                {{ 'detailsPage.nextMaintenance.nextMaintenanceDate' | translate }}&nbsp;
            </div>
            <div fxLayout="column">
                <div *ngFor="let nextMaintenance of systemDetails.nextMaintenance">
                    {{nextMaintenance.dateString}}
                </div>
            </div>
        </div>
    </div>
</mat-card>
