import {Component, OnInit, OnDestroy, Input, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {Structure} from '../../../models/structure.model';
import {MatPaginator} from '@angular/material/paginator';
import {environment} from '../../../../environments/environment';
import {UnregisteredSystem} from '../../../models/unregistered-system.model';
import {UserInformationService} from '../../../services/user-information.service';
import {last} from "rxjs/operators";


@Component({
    selector: 'vi-system-structure',
    templateUrl: './structure.component.html',
    styleUrls: ['./structure.component.scss']
})
export class StructureComponent implements OnInit, OnDestroy {

    @Input() equipmentStructure: Structure[];
    @Input() unregisteredSystems: UnregisteredSystem[];
    @Input() vitoguideId: string;
    displayedColumnsStructure: string[] = ['description', 'serialNumber', 'startUpDate', 'warrantyStart', 'warrantyEnd', 'vitoguide', 'shop', 'partnerPortal'];
    dataSourceStructure = new MatTableDataSource<Structure>();
    vitoGuideUrl = environment.viGuide;
    shopUrl = environment.shopUrl;
    shopSerialUrl = environment.shopSerialUrl;
    partnerPortalUrl = environment.partnerPortalUrl;
    partnerPortalDEForm = environment.partnerPortalDEFormGeneral;
    partnerPortalNLForm = environment.partnerPortalNLFormGeneral;
    partnerPortalFRForm = environment.partnerPortalFRFormGeneral;
    partnerPortalITForm = environment.partnerPortalITFormGeneral;
    entries: number;
    private paginator: MatPaginator;
    @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
        this.paginator = mp;
        this.setDataSourceAttributes();
    }

    constructor(public userInformationService: UserInformationService) {
    }

    setDataSourceAttributes() {
        this.dataSourceStructure.paginator = this.paginator;
    }

    ngOnInit() {
        this.dataSourceStructure.data = this.equipmentStructure;
        this.entries = this.equipmentStructure.length;
        this.formatPartnerPortalUrl();
    }

    ngOnDestroy(): void {
    }

    formatPartnerPortalUrl() {
        this.partnerPortalUrl = this.partnerPortalUrl.replace('{languageCode}', this.userInformationService.language)
            .replace('{countryCode}', this.userInformationService.country.toLowerCase());
        if (this.userInformationService.country === 'BE') {
            if (this.userInformationService.language === 'nl') {
                this.partnerPortalUrl = this.partnerPortalUrl.concat(this.partnerPortalNLForm);
            } else if (this.userInformationService.language === 'fr') {
                this.partnerPortalUrl = this.partnerPortalUrl.concat(this.partnerPortalFRForm);
            }
        } else if (this.userInformationService.country === 'DE')    {
            this.partnerPortalUrl = this.partnerPortalUrl.concat(this.partnerPortalDEForm);
        } else if (this.userInformationService.country === 'IT') {
            this.partnerPortalUrl = this.partnerPortalUrl.concat(this.partnerPortalITForm);
        }
    }

    checkSerialNumber16(serialNumber: string) {
        return serialNumber.length >= 16 && serialNumber.slice(0, 16).match('[0-9]{16}');
    }

    checkSerialNumber7(serialNumber: string) {
        return !this.checkSerialNumber16 && serialNumber.length >= 7 && serialNumber.slice(0, 7).match('[0-9]{7}');
    }
}
