import {Component, Inject} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {UserInformationService} from '../../../services/user-information.service';
import {System} from '../../../models/system-overview.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';

export interface DialogData {
    serialNumber: string;
    context: string;
}


@Component({
    selector: 'app-not-found-popup',
    templateUrl: 'not-found-popup.component.html',
    styleUrls: ['./not-found-popup.component.scss']
})
export class NotFoundPopupComponent {


    constructor(
        public dialogRef: MatDialogRef<NotFoundPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        public userInformationService: UserInformationService,
        private router: Router) {
    }

    close(): void {
        if (this.data.context === 'find-by-serialnumber') {
            this.router.navigate(['/list']);
        }
        this.dialogRef.close();
    }

}
