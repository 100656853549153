import {Component, OnInit, OnDestroy, Input, ViewChild} from '@angular/core';
import {Contract} from "../../../models/contract.model";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {SystemDetail} from '../../../models/system-detail.model';
import {environment} from "../../../../environments/environment";
import {UserInformationService} from "../../../services/user-information.service";


@Component({
    selector: 'vi-system-offers',
    templateUrl: './offers.component.html',
    styleUrls: ['./offers.component.scss']
})
export class OffersComponent implements OnInit, OnDestroy {

    @Input() equipmentContracts: Contract[];
    @Input() systemDetails: SystemDetail;
    displayedColumnsContract: string[] = ['contractNumber', 'posNumber', 'description', 'runTimeAndEqui', 'hasAttachment', 'orderType'];
    dataSourceContract = new MatTableDataSource<Contract>();
    shopOfferUrl = environment.shopOfferUrl;
    entries: number;
    private paginator: MatPaginator;
    @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
        this.paginator = mp;
        this.setDataSourceAttributes();
    }

    constructor(public userInformationService: UserInformationService) {
    }

    setDataSourceAttributes() {
        this.dataSourceContract.paginator = this.paginator;
    }

    ngOnInit() {
        this.dataSourceContract.data = this.equipmentContracts;
        this.entries = this.equipmentContracts.length;
    }

    ngOnDestroy(): void {

    }

}
