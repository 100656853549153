import {Component, OnInit, OnDestroy, Input} from '@angular/core';
import {Equipment} from "../../../models/equipment.model";
import {Scheme} from '../../../models/scheme.model';
import {SystemDetailService} from '../../../services/system-detail.service';
import {UserInformationService} from '../../../services/user-information.service';
import {TranslateService} from '@ngx-translate/core';


@Component({
    selector: 'vi-system-equipment',
    templateUrl: './equipment.component.html',
    styleUrls: ['./equipment.component.scss']
})
export class EquipmentComponent implements OnInit, OnDestroy {

    @Input() equipmentInformation: Equipment;
    @Input() schemes: Scheme[];
    public color = 'primary';
    public mode = 'indeterminate';
    public value = 50;
    displayProgressSpinner: boolean;

    constructor(private systemDetailService: SystemDetailService, private userInformationService: UserInformationService,
                private translateService: TranslateService) {
    }

    ngOnInit() {

    }

    ngOnDestroy(): void {

    }

    openScheme(scheme: Scheme) {
        this.systemDetailService.getPDFDVS(scheme.equipment, scheme.documentCategory, scheme.documentNumber, scheme.documentVersion, scheme.documentPart, 'D')
            .subscribe(
            document => {
                const binaryData = [];
                binaryData.push(document);
                const url = URL.createObjectURL(new Blob(binaryData, {type: 'application/pdf'}));
                window.open(url, '_blank');
            }
        );
    }

    openCertificate() {
        this.displayProgressSpinner = true;
        this.systemDetailService.getCertificate(this.equipmentInformation.equipmentNumber, this.userInformationService.language)
            .subscribe(
                file => {
                    this.displayProgressSpinner = false;
                    const binaryData = [];
                    binaryData.push(file);
                    const url = URL.createObjectURL(new Blob(binaryData, {type: 'application/pdf'}));
                    // window.open(url, '_blank');
                    const a = document.createElement('a');
                    a.href        = url;
                    a.target      = '_blank';
                    a.download    = this.translateService.instant('fileName.certificate') + '_' + this.equipmentInformation.equipmentNumber + '_'
                        + this.equipmentInformation.description + '.pdf';
                    document.body.appendChild(a);
                    a.click();
                }
            );
    }

    openMaintenanceProof() {
        this.displayProgressSpinner = true;
        this.systemDetailService.getMaintenanceProof(this.equipmentInformation.equipmentNumber, this.userInformationService.language)
            .subscribe(
                document => {
                    this.displayProgressSpinner = false;
                    const binaryData = [];
                    binaryData.push(document);
                    const url = URL.createObjectURL(new Blob(binaryData, {type: 'application/pdf'}));
                    window.open(url, '_blank');
                }
            );
    }
}
