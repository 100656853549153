import {Component} from '@angular/core';

/**
 * @title Basic progress-spinner
 */
@Component({
    selector: 'progress-spinner',
    templateUrl: 'progress-spinner.component.html',
    styleUrls: ['progress-spinner.component.scss'],
})
export class ProgressSpinnerOverview {}
