<div fxLayout="column" style="margin-bottom: 16px">
    <mat-dialog-content>
        <div style="display: flex; flex-direction: column;">
            <div class="text" *ngFor="let text of data.longtext">
                {{ text }}
            </div>
        </div>
    </mat-dialog-content>
</div>
<div mat-dialog-actions class="button-row">
    <button mat-stroked-button color="primary" (click)="close()"> {{ 'buttons.ok' | translate }}</button>
</div>
