<section *ngIf="(status === 'partial_outage' || status === 'degraded_performance') && welcomePage" class="section" >
  <div>
      <mat-card class="custom-elevation" style="margin: 0 1rem 1rem">
          <div>
            <div style="display: inline-block; width: 10%;vertical-align: top;"><mat-icon aria-hidden="false" aria-label="Example info icon">info</mat-icon></div>
              <div *ngIf="status === 'partial_outage'" style="display: inline-block; width: 80%;">{{'COMMON.MAINTENANCE.MESSAGE.PARTIAL' | translate}}</div>
              <div *ngIf="status === 'degraded_performance'" style="display: inline-block; width: 80%;">{{'COMMON.MAINTENANCE.MESSAGE.DEGRADED' | translate }}</div>
          </div>
      </mat-card>
  </div>
</section>
