<mat-toolbar color="primary">
    <mat-toolbar-row fxLayout>
        <img fxFlex="150px" fxHide.xs="true" width="150px" height="25px" src="assets/images/vi-logo.svg"
             viewBox="0 0 275 60" class="logo-img" alt="Viessmann">
        <div fxFlex>
            <div fxLayout fxLayoutAlign="space-between center">
                <span fxFlex class="text-overflow">{{ 'application.title' | translate }}</span>
            </div>
        </div>
        <div fxFlex="row" fxLayoutAlign="flex-end">
            <button mat-icon-button style="top:-1px;" (click)="openFeedbackDialog()">
            <span class="mat-button-wrapper">
              <img  src="assets/images/mail_white_24dp.svg">
            </span>
            </button>
            <vih-support-link class="disable-hover"></vih-support-link>
            <vih-app-switcher></vih-app-switcher>
            <vih-login (logoutEvent)="logout()" [appManagesAuthentication]="true" [authenticated]="authenticated$"></vih-login>
        </div>
    </mat-toolbar-row>
</mat-toolbar>
