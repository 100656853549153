<!-- Input for Customer-ID -->
<div fxFlex *ngIf="userRole == 'ViInt' || userRole == 'ViExt'" style="margin-bottom: 16px">
    <div style="margin: 16px auto auto; padding: 0 1rem; max-width: 1408px; ">
        <div class="custom-elevation" fxLayout="row wrap" style="padding: 24px;">
            <div  fxFlex="0 0 300px" fxFlex.lt-sm="0 0 260px" style="margin-right: 8px;">
                <mat-form-field appearance="outline" style="width: 100%;">
                    <mat-label class="label-appearance">{{ 'customerSearch.label' | translate }}</mat-label>
                    <mat-icon matPrefix class="prefix">search</mat-icon>
                    <input name="customerSearch" class="search-input" type="number" maxlength="10" matInput (keyup)="onKey($event)"
                           [(ngModel)]="this.userInformationService.shownCustomer">
                </mat-form-field>
            </div>
            <div  fxFlex="0 0 300px" fxFlex.lt-sm="0 0 260px" style="margin-right: 8px;">
                <mat-form-field appearance="outline" style="width: 100%;">
                    <mat-label class="label-appearance">{{ 'kEquiSearch.label' | translate }}</mat-label>
                    <mat-icon matPrefix class="prefix">search</mat-icon>
                    <input name="equiSearch" class="search-input" type="number" matInput (keyup)="onKey($event)"
                           [(ngModel)]="this.userInformationService.equiSearch">
                </mat-form-field>
            </div>
            <div  fxFlex="0 0 300px" fxFlex.lt-sm="0 0 260px" style="margin-right: 8px;">
                <mat-form-field appearance="outline" style="width: 100%;">
                    <mat-label class="label-appearance">{{ 'serialSearch.label' | translate }}</mat-label>
                    <mat-icon matPrefix class="prefix">search</mat-icon>
                    <input name="serialSearch" class="search-input" matInput (keyup)="onKey($event)"
                           [(ngModel)]="this.userInformationService.serialSearch">
                </mat-form-field>
            </div>
            <div style="padding-top: 11px">
                <button (click)="search()" mat-stroked-button color="primary">
                    {{ 'buttons.search' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
