<div class="cdk-overlay-pane" style="max-width: 80vw; pointer-events: auto; position: static">
        <mat-dialog-content class="universal-dialog-content mat-dialog-content">
            <div style="flex-direction: row; box-sizing: border-box; display: flex">
                <div style="flex: 1 1 100%; box-sizing: border-box; max-width: 80%">
                    <p>
                        Die Angaben zu Inbetriebnahmedaten und Garantiedaten Ihrer Anlagen sind nicht vollständig.
                    </p>
                </div>
                <div fxFlex="20%" class="icon-warning" style="flex: 1 1 100%; box-sizing: border-box; max-width: 20%">
                    <mat-icon aria-hidden="true">error_outline</mat-icon>
                </div>
            </div>
        </mat-dialog-content>
</div>
<mat-dialog-actions class="mat-dialog-actions">
    <button color="primary" mat-raised-button type="button" mat-dialog-close>
        <span class="mat-button-wrapper">Weiter</span>
    </button>
</mat-dialog-actions>
