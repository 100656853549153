import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {Subscription} from 'rxjs';

@Component({
    selector: 'vi-equi-not-found',
    templateUrl: './equi-not-found.component.html',
    styleUrls: ['./equi-not-found.component.scss']
})
export class EquiNotFoundComponent {


    constructor(private activatedRoute: ActivatedRoute, private router: Router, public dialog: MatDialog, ) {
    }

    private subscription: Subscription;
    equipmentNumber: number;
    customerID: number;

    ngOnInit() {
    this.subscription = this.activatedRoute.params.subscribe(
    params => {
        this.equipmentNumber = +params['equinumber'];
        this.customerID = +params['customerID'];
        });
    }


        back(){
            this.router.navigate(['../list']);
        }
}
