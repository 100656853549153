<div class="custom-elevation" flex="row">
    <div fxFlex="0 0 25%">
        <img mat-card-image src="../../../assets/images/no-installation.png" style="padding: 29px 27px 34px; max-width: 245px; min-width: 25%">
    </div>
    <div class="text-div">
        <div style="padding-bottom: 16px">
            {{ 'notRegisteredDialog.notFullyRegistered' | translate }}
        </div>
        <div *ngIf="!this.userInformationService.isVi()">
                <a mat-raised-button
                   href="{{heatingSystemRegistrationEquiUrl.replace('{systemId}', this.equipmentNumber.toString()) + '&serialnumber=' + (this.userInformationService.serialSearch ? this.userInformationService.serialSearch : this.serialNumber)}}"
                   color="primary">
                    {{ 'buttons.changeEqui' | translate }}
                </a>
        </div>
    </div>
</div>
