import {Component, OnInit, OnDestroy, Input, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {History} from "../../../models/history.model";
import {MatPaginator} from "@angular/material/paginator";
import {SystemDetailService} from '../../../services/system-detail.service';
import {SystemDetail} from '../../../models/system-detail.model';
import {UserInformationService} from '../../../services/user-information.service';
import {environment} from "../../../../environments/environment";
import {NotFoundPopupComponent} from '../../systems-overview/not-found-popup/not-found-popup.component';
import {MatDialog} from '@angular/material/dialog';
import {LongtextPopupComponent} from './longtext-popup/longtext-popup.component';


@Component({
    selector: 'vi-system-history',
    templateUrl: './history.component.html',
    styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit, OnDestroy {

    equipmentHistory: History[];
    @Input() systemDetails: SystemDetail;
    displayedColumnsHistory: string[] = ['orderNumber', 'date', 'type', 'description', 'hasOrderAttachment', 'statusDisposition', 'partnerPortal'];
    dataSourceHistory = new MatTableDataSource<History>();
    entries: number;
    partnerPortalUrl = environment.partnerPortalUrl;
    partnerPortalDEForm = environment.partnerPortalDEFormService;
    partnerPortalNLForm = environment.partnerPortalNLFormService;
    partnerPortalFRForm = environment.partnerPortalFRFormService;
    partnerPortalITForm = environment.partnerPortalITFormService;

    private paginator: MatPaginator;
    @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
        this.paginator = mp;
        this.setDataSourceAttributes();
    }

    constructor(public userInformationService: UserInformationService, public dialog: MatDialog) {
    }

    setDataSourceAttributes() {
        this.dataSourceHistory.paginator = this.paginator;
    }

    ngOnInit() {
        this.equipmentHistory = this.systemDetails.history;
        this.dataSourceHistory.data = this.equipmentHistory;
        this.entries = this.equipmentHistory.length;
        this.formatPartnerPortalUrl();
    }

    formatPartnerPortalUrl() {
        this.partnerPortalUrl = this.partnerPortalUrl.replace('{languageCode}', this.userInformationService.language)
            .replace('{countryCode}', this.userInformationService.country.toLowerCase());
        if (this.userInformationService.country === 'BE') {
            if (this.userInformationService.language === 'nl') {
                this.partnerPortalUrl = this.partnerPortalUrl.concat(this.partnerPortalNLForm);
            } else if (this.userInformationService.language === 'fr') {
                this.partnerPortalUrl = this.partnerPortalUrl.concat(this.partnerPortalFRForm);
            }
        } else if (this.userInformationService.country === 'DE')    {
            this.partnerPortalUrl = this.partnerPortalUrl.concat(this.partnerPortalDEForm);
        } else if (this.userInformationService.country === 'IT')    {
            this.partnerPortalUrl = this.partnerPortalUrl.concat(this.partnerPortalITForm);
        }
    }

    ngOnDestroy(): void {

    }

    openLongtextDialog(longtext: string): void {
        this.dialog.open(LongtextPopupComponent, {
            width: '650px',
            data: {
                longtext: longtext
            }
        });
    }

}
