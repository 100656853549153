import {AppComponent, ConfigService, configServiceFactory} from './app.component';
import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import localeDe from '@angular/common/locales/de';
import {registerLocaleData} from '@angular/common';
import {MaterialModule} from "./material.module";
import {BrowserModule} from "@angular/platform-browser";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RoutingModule} from "./app.router";
import { SystemsOverviewComponent } from './components/systems-overview/systems-overview.component';
import { SystemDetailComponent} from './components/system-detail/system-detail.component';
import { SystemsOverviewService } from './services/systems-overview.service';
import { SystemDetailService } from './services/system-detail.service';
import {PageNotFoundComponent} from "./components/page-not-found/page-not-found.component";
import {IncompleteInformationComponent} from "./components/system-detail/incomplete-information/incomplete-information.component";
import {ProgressSpinnerOverview} from "./components/progress-spinner/progress-spinner.component";
import {ContentComponent} from "./components/content/content.component";
import {EquipmentComponent} from "./components/system-detail/equipment/equipment.component";
import {ContractsComponent} from "./components/system-detail/contracts/contracts.component";
import {HistoryComponent} from "./components/system-detail/history/history.component";
import {LocationComponent} from "./components/system-detail/location/location.component";
import {StructureComponent} from "./components/system-detail/structure/structure.component";
import {CertificateComponent} from "./components/system-detail/certificate/certificate.component";
import {CertificateService} from "./services/certificate.service";
import {MatPaginatorIntl} from "@angular/material/paginator";
import {ClientPaginatorComponent} from "./components/client-paginator/client-paginator.component";
import {HeaderComponent} from "./components/header/header.component";
import {WelcomeComponent} from "./components/welcome-page/welcome.component";
import {AuthGuard} from "./security/guards/auth.guard";
import {CustomerSearchComponent} from "./components/customer-search/customer-search.component";
import {WelcomeGuard} from "./security/guards/welcome.guard";
import {NoSystemsFoundComponent} from "./components/no-systems-found/no-systems-found.component";
import {CsrfTokenService} from './services/csrf-token.service';
import {CSRFTokenInterceptor} from './Interceptors/csrf-token.interceptor';
import {FeedbackDialogComponent} from './components/feedback-dialog/feedback-dialog.component';
import {UserInformationService} from './services/user-information.service';
import {GlobalHeaderModule} from '@vi/global-header';
import {environment} from '../environments/environment';
import {EquiNotFoundComponent} from './components/equi-not-found/equi-not-found.component';
import {EquiNotAllowedComponent} from './components/equi-not-allowed/equi-not-allowed.component';
import {UnregisteredSystemsComponent} from './components/system-detail/structure/unregistered-systems/unregistered-systems.component';
import {NotRegistratedPopupComponent} from './components/systems-overview/not-registrated-popup/not-registrated-popup.component';
import {EquiIsSEquiComponent} from './components/equi-is-s-equi/equi-is-s-equi.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {NotFoundPopupComponent} from './components/systems-overview/not-found-popup/not-found-popup.component';
import {FindBySerialnumberComponent} from './components/find-by-serialnumber/find-by-serialnumber.component';
import {EquiNotFullyRegisteredComponent} from './components/equi-not-fully-registered/equi-not-fully-registered.component';
import {PdfMenuComponent} from './components/system-detail/history/pdf-menu/pdf-menu.component';
import {LiteAccountComponent} from './components/lite-account-page/lite-account.component';
import {FooterComponent} from './components/footer/footer.component';
import {ContractPdfMenuComponent} from './components/system-detail/contracts/pdf-menu/contract-pdf-menu.component';
import {MaintenanceBannerComponent} from './components/maintenance-banner/maintenance-banner.component';
import {MaintenanceComponent} from './components/maintenance/maintenance.component';
import {TranslationConfigService} from './services/translation-config.service';
import {CountryNotSupportedComponent} from './components/country-not-supported-page/country-not-supported.component';
import {PartnerComponent} from './components/system-detail/partner/partner.component';
import {OffersComponent} from './components/system-detail/offers/offers.component';
import {OfferPdfMenuComponent} from './components/system-detail/offers/pdf-menu/offer-pdf-menu.component';
import {NextMaintenanceComponent} from "./components/system-detail/next-maintenance/next-maintenance.component";
import {PerformanceIssuesBannerComponent} from './components/performance-issues-banner/performance-issues-banner.component';
import {LongtextPopupComponent} from './components/system-detail/history/longtext-popup/longtext-popup.component';
import {ViessmannTranslationsAPIHttpLoader} from './services/ViessmannTranslationsAPIHttpLoader';
import {FallbackTranslateHttpLoader} from './services/fallback-translate-httpLoader';
import {AppOverlayModule} from './services/overlay/overlay.module';
import {ProgressSpinnerComponent2} from './components/progress-spinner-2/progress-spinner.component';

registerLocaleData(localeDe);

export function createTranslateLoader(http: HttpClient) {

    return new FallbackTranslateHttpLoader(http, environment.translationUrl, '/download?file_format=i18next');
    //return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        SystemsOverviewComponent,
        SystemDetailComponent,
        PageNotFoundComponent,
        IncompleteInformationComponent,
        ProgressSpinnerOverview,
        ProgressSpinnerComponent2,
        ContentComponent,
        EquipmentComponent,
        ContractsComponent,
        OffersComponent,
        NextMaintenanceComponent,
        HistoryComponent,
        LocationComponent,
        StructureComponent,
        CertificateComponent,
        HeaderComponent,
        WelcomeComponent,
        CustomerSearchComponent,
        NoSystemsFoundComponent,
        FeedbackDialogComponent,
        LiteAccountComponent,
        EquiNotFoundComponent,
        EquiNotAllowedComponent,
        EquiNotFullyRegisteredComponent,
        UnregisteredSystemsComponent,
        NotRegistratedPopupComponent,
        NotFoundPopupComponent,
        EquiIsSEquiComponent,
        FindBySerialnumberComponent,
        PdfMenuComponent,
        FooterComponent,
        ContractPdfMenuComponent,
        OfferPdfMenuComponent,
        MaintenanceComponent,
        MaintenanceBannerComponent,
        PerformanceIssuesBannerComponent,
        CountryNotSupportedComponent,
        PartnerComponent,
        LongtextPopupComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        MaterialModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        ReactiveFormsModule,
        RoutingModule,
        GlobalHeaderModule.forRoot({
            logoutRedirectUrl: environment.applicationBaseUrl,
            baseUrl: environment.headerBaseUrl,
            accountAppUrl: environment.headerAccountAppUrl,
            partnerPortalBaseUrl: environment.partnerPortalBaseUrl,
            appId: environment.IAMConfig.auth.appId
        }),
        AppOverlayModule
    ],
    providers: [
        { provide: MatPaginatorIntl, useClass: ClientPaginatorComponent},
        { provide: HTTP_INTERCEPTORS, useClass: CSRFTokenInterceptor, multi: true },
        SystemsOverviewService,
        SystemDetailService,
        CertificateService,
        CsrfTokenService,
        UserInformationService,
        AuthGuard,
        WelcomeGuard,
        TranslationConfigService,
        { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
        { provide: LOCALE_ID, useValue: 'de-DE' },
        ConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: configServiceFactory,
            deps: [ConfigService],
            multi: true
        }
    ],
    bootstrap: [AppComponent],
    exports: [
        SystemsOverviewComponent,
        SystemDetailComponent
    ]
})
export class AppModule {

}
