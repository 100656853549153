import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {MaintenanceService, StatusSummary} from '../../services/maintenance.service';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceGuard implements CanActivate {

  constructor(private maintenanceService: MaintenanceService, private router: Router) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    console.log('checking for maintenance ' + environment.statusCheckActive);
    if (environment.statusCheckActive) {
      return this.maintenanceService.fetchStatus().pipe(
        map(
          (status) => this.checkStatus(status),
          catchError(() => of(true))
        )
      );
    }
    else return of(true);
  }

  private checkStatus(status: StatusSummary): boolean {
    switch (status.status) {
      case 'major_outage':
      case 'under_maintenance': {
        this.router.navigate(['maintenance'], {
          state: status,
        });
        return false;
      }

      default:
        return true;
    }
  }

}
