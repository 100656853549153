import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatMenu, MatMenuTrigger} from '@angular/material/menu';
import {SystemDetailService} from '../../../../services/system-detail.service';
import {UserInformationService} from '../../../../services/user-information.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Attachment} from '../../../../models/attachment.model';
import {SystemDetail} from '../../../../models/system-detail.model';
import {History} from '../../../../models/history.model';
import {OffersComponent} from '../offers.component';
import {Contract} from '../../../../models/contract.model';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'vi-offer-pdf-menu',
    templateUrl: './offer-pdf-menu.component.html',
    styleUrls: ['./offer-pdf-menu.component.scss']
})
export class OfferPdfMenuComponent {

    constructor(private systemDetailService: SystemDetailService, private userInformationService: UserInformationService,
                private _snackBar: MatSnackBar, private translateService: TranslateService) {
    }

    attachments: Attachment[] = [];
    @Input() systemDetails: SystemDetail;
    @Input() contractItem: Contract;
    @ViewChild('menuTrigger') clickHoverMenuTrigger: MatMenuTrigger;
    @ViewChild('attachmentMenu') matMenu: MatMenu;


    getOfferAttachments(id, offer, language){
        this.closeMatMenu();
        if (this.attachments.length == 0) {
            this.systemDetailService.getOfferAttachments(id, offer, language).subscribe((attachments) => {
                this.attachments = attachments;
                if (this.attachments.length == 1) {
                    this.openPDFGOS(this.systemDetails.equipment.equipmentNumber, this.attachments[0]);
                } else if (this.attachments.length > 1) {
                    this.openMatMenu();
                } else {
                    this._snackBar.open(this.translateService.instant('alert.noDocumentsForContract'), '', {
                        duration: 2000,
                    });
                }
            })
        } else {
            if (this.attachments.length == 1) {
                this.openPDFGOS(this.systemDetails.equipment.equipmentNumber, this.attachments[0]);
            } else if (this.attachments.length > 1) {
                this.openMatMenu();
            }
        }
    }

    openPDFGOS(id, attachment){
        this.systemDetailService.getPDFGOS(id, attachment).subscribe((response) => {
            const binaryData = [];
            binaryData.push(response);
            const url = URL.createObjectURL(new Blob(binaryData, {type: "application/pdf"}));
            window.open(url, '_blank');
        });
    }

    closeMatMenu() {
        this.clickHoverMenuTrigger.closeMenu();
    }
    openMatMenu() {
        this.clickHoverMenuTrigger.openMenu();
    }

}




