import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'vi-content',
    templateUrl: './content.component.html',
    styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {

    @Input() maxWidth: string;

    ngOnInit() {
    }
}
