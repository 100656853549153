import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {SystemsOverviewComponent} from "./components/systems-overview/systems-overview.component";
import {SystemDetailComponent} from "./components/system-detail/system-detail.component";
import {PageNotFoundComponent} from "./components/page-not-found/page-not-found.component";
import {WelcomeComponent} from "./components/welcome-page/welcome.component";
import {AuthGuard} from "./security/guards/auth.guard";
import {WelcomeGuard} from "./security/guards/welcome.guard";
import {FindBySerialnumberComponent} from './components/find-by-serialnumber/find-by-serialnumber.component';
import {LiteAccountComponent} from './components/lite-account-page/lite-account.component';
import {MaintenanceGuard} from './security/guards/maintenance.guard';
import {MaintenanceComponent} from './components/maintenance/maintenance.component';
import {CountryNotSupportedComponent} from './components/country-not-supported-page/country-not-supported.component';


const routes: Routes = [
    {path: 'welcome', component: WelcomeComponent, canActivate: [WelcomeGuard]},
    {path: 'lite-account', component: LiteAccountComponent, canActivate: [AuthGuard]},
    {path: 'country-not-supported', component: CountryNotSupportedComponent, canActivate: [AuthGuard]},
    {path: 'page-not-found', component: PageNotFoundComponent},
    {path: 'maintenance', component: MaintenanceComponent},
    {path: '', redirectTo: 'welcome', pathMatch: 'full'},
    {path: 'list', component: SystemsOverviewComponent, canActivate: [AuthGuard, MaintenanceGuard]},
    {path: 'details/find-by-serialnumber/:serialnumber', component: FindBySerialnumberComponent, canActivate: [AuthGuard, MaintenanceGuard]},
    {path: 'details/:equinumber', component: SystemDetailComponent, canActivate: [AuthGuard, MaintenanceGuard]},
    {path: '**', redirectTo: 'welcome', pathMatch: 'full'},
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, /*{enableTracing: true}*/)
    ],
    exports: [RouterModule]
})

export class RoutingModule {
}
