<mat-card class="custom-elevation">
    <mat-card-title> {{ 'detailsPage.offer.offer' | translate }}</mat-card-title>
    <mat-card-content
        *ngIf="equipmentContracts.length == 0">{{ 'detailsPage.noEntries' | translate }}</mat-card-content>
    <table mat-table [dataSource]="dataSourceContract" *ngIf="equipmentContracts.length > 0">
        noEntries
        <ng-container matColumnDef="contractNumber">
            <th mat-header-cell *matHeaderCellDef> {{ 'detailsPage.offer.offerNumber' | translate }}</th>
            <td mat-cell *matCellDef="let element"> {{element.contractNumber}} </td>
        </ng-container>

        <ng-container matColumnDef="posNumber">
            <th mat-header-cell *matHeaderCellDef> {{ 'detailsPage.offer.position' | translate }}</th>
            <td mat-cell *matCellDef="let element"> {{element.posNumber}} </td>
        </ng-container>

        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> {{ 'detailsPage.offer.description' | translate }}</th>
            <td mat-cell *matCellDef="let element"> {{element.description}} </td>
        </ng-container>

        <ng-container matColumnDef="runTimeAndEqui">
            <th mat-header-cell *matHeaderCellDef> {{ 'detailsPage.offer.term' | translate }}</th>
            <td mat-cell *matCellDef="let element"> {{element.runTimeAndEqui}} </td>
        </ng-container>

        <ng-container matColumnDef="hasAttachment">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="element.hasAttachments">
                    <vi-offer-pdf-menu [systemDetails]="systemDetails" [contractItem]="element"></vi-offer-pdf-menu>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="orderType">
            <th mat-header-cell *matHeaderCellDef class="icon-column"></th>
            <td mat-cell *matCellDef="let element" class="icon-column">
                <div *ngIf="(element.orderType === 'ZSKM' || element.orderType === 'ZSKB') && this.userInformationService.user.role !== 'EK'">
                    <a
                        href="{{shopOfferUrl}}/import/{{element.contractNumber}}?cartType=CC"
                        target="_blank" rel="noopener noreferrer">
                        <img
                            matTooltip="{{ 'buttons.shop.offer' | translate }}"
                            style="vertical-align: bottom;"
                            fxFlex="30px" width="30px" height="30px"
                            src="assets/images/obs-app-icon.svg" viewBox="0 0 275 60" alt="Viessmann Shop">
                    </a>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsContract"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsContract;"></tr>
    </table>
    <mat-paginator *ngIf="equipmentContracts.length > 10" [pageSizeOptions]="[10, this.entries]"
                   showFirstLastButtons></mat-paginator>
</mat-card>
