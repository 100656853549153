import {Component, OnInit} from '@angular/core';
import {MaintenanceService, Status} from '../../services/maintenance.service';
import {NavigationEnd, Router, RouterEvent} from '@angular/router';
import {filter} from 'rxjs/operators';

@Component({
    selector: 'app-performance-issues-banner',
    templateUrl: './performance-issues-banner.component.html',
    styleUrls: ['./performance-issues-banner.component.scss']
})
export class PerformanceIssuesBannerComponent implements OnInit {
    public status: Status;
    welcomePage: boolean;

    constructor(private maintenanceService: MaintenanceService, private router: Router) {
        maintenanceService.fetchStatus().subscribe((status) => {
            this.status = status.status;
            this.welcomePage = !this.router.url.includes('welcome');
        });
        this.router.events
            .pipe(
                filter( event => event instanceof NavigationEnd)
            )
            .subscribe(
                (event: NavigationEnd) => {
                    this.welcomePage = !this.router.url.includes('welcome');
                }
            );
    }

    ngOnInit(): void {
    }

}
