import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {environment} from '../../../environments/environment';
import {SystemDetail} from '../../models/system-detail.model';

@Component({
    selector: 'vi-equi-is-s-equi',
    templateUrl: './equi-is-s-equi.component.html',
    styleUrls: ['./equi-is-s-equi.component.scss']
})
export class EquiIsSEquiComponent implements OnInit {


    constructor(private activatedRoute: ActivatedRoute, private router: Router, public dialog: MatDialog,) {
    }

    private subscription: Subscription;
    equipmentNumber: number;
    customerID: number;
    @Input() serialNumber: string;
    heatingSystemRegistrationSerialNumberUrl = environment.heatingSystemRegistrationSerialnumber;

    ngOnInit() {
        this.subscription = this.activatedRoute.params.subscribe(
            params => {
                this.equipmentNumber = +params['equinumber'];
                this.customerID = +params['customerID'];
            });
    }

    toOverview() {
        this.router.navigate(['../list']);
    }
}
