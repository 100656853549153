import {Component, OnInit, Input, ViewChild, OnDestroy, AfterViewInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {System} from '../../models/system-overview.model';
import {SystemsOverviewService} from '../../services/systems-overview.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {SystemDetailService} from '../../services/system-detail.service';
import {Subscription} from 'rxjs';
import {UserInformationService} from '../../services/user-information.service';
import {environment} from '../../../environments/environment';
import {MatDialog} from '@angular/material/dialog';
import {NotRegistratedPopupComponent} from './not-registrated-popup/not-registrated-popup.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NotFoundPopupComponent} from './not-found-popup/not-found-popup.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'vi-systems-overview',
    templateUrl: './systems-overview.component.html',
    styleUrls: ['./systems-overview.component.scss']
})
export class SystemsOverviewComponent implements OnInit, OnDestroy, AfterViewInit {

    @Input() maxWidth: string;
    systems: System[];
    systemsBackup: System[];
    systemsLoaded: Promise<boolean>;
    systemsFound: boolean;
    showSpinner: boolean = true;
    connectivitySubscription: Subscription[] = [];
    registrationSubscription: Subscription[] = [];
    entries: number;
    refreshSubscription: any;
    heatingSystemRegistrationBaseUrl = environment.heatingSystemRegistrationBase;
    heatingSystemRegistrationEquiUrl = environment.heatingSystemRegistrationEqui;
    fioriEquipmentUrl = environment.fioriEquipmentUrl;
    viGuideBaseUrl = environment.viGuideBaseUrl;
    isLoading = true;
    isStarted = false;
    systemsWithDelay = false;
    params: any;
    private subscription: Subscription;

    private paginator: MatPaginator;

    @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
        this.paginator = mp;
        this.setDataSourceAttributes();
    }

    setDataSourceAttributes() {
        this.dataSourceSystem.paginator = this.paginator;
    }

    constructor(
        private systemOverviewService: SystemsOverviewService, private systemDetailService: SystemDetailService,
        private activatedRoute: ActivatedRoute, private router: Router, public userInformationService: UserInformationService,
        public dialog: MatDialog, private _snackBar: MatSnackBar, private translateService: TranslateService
    ) {
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };

        this.subscription = this.userInformationService.appReadyChanged$.subscribe((ready) => {
            if (!this.isStarted) {
                this.handleAppStart();
                this.isStarted = true;
            }
        });

        this.refreshSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                // Trick the Router into believing it's last link wasn't previously loaded
                this.router.navigated = false;
            }
        });

        this.activatedRoute.queryParams.subscribe(params => {
            this.params = params;
        });
    }

    async handleAppStart() {

        this.startComponent();
        this.isLoading = false;

    }

    ngOnInit() {
        this.userInformationService.initCurrentUser();
    }

    startComponent() {
        if (this.params.customer && (this.userInformationService.isVi())) {
            this.userInformationService.shownCustomer = this.params.customer;
        }
        if (this.userInformationService.isEK() || this.userInformationService.shownCustomer !== '') {
            this.systemOverviewService.getByCustomerid(this.userInformationService.shownCustomer)
                .subscribe(data => {
                    this.systemsFound = data.length === 0 || (data.filter(system => system.isRegistrated === '1' || system.isRegistrated === '3').length === 0 && !this.userInformationService.isVi());
                    this.systems = data;
                    this.systems.sort(this.compareSystems);
                    this.systemsLoaded = Promise.resolve(true);
                    this.showSpinner = false;
                    if (this.systems.length < 1000) {
                        this.addSystemsToTable();
                    } else {
                        this.systemsBackup = this.systems;
                        this.systems = [];
                        let counter = 0;
                        const steps = Math.ceil(this.systemsBackup.length / 1000);
                        this.addSystemsWithDelay(counter);
                        this.systemsWithDelay = true;
                        counter++;
                        let systemsIntervall = setInterval(() => {
                            if (counter < steps) {
                                this.addSystemsWithDelay(counter);
                                counter++;
                                if (counter == steps) {
                                    this.systemsWithDelay = false;
                                }
                            } else {
                                clearInterval(systemsIntervall);
                            }
                        }, 4000);
                    }
                }, error => {
                    this._snackBar.open(this.translateService.instant('alert.customerNotFound'), '', {
                        duration: 2000,
                    });
                    this.systemsFound = true;
                    this.showSpinner = false;
                });
        } else {
            this.systemsFound = true;
            this.showSpinner = false;
        }
    }

    displayedColumns: string[] = ['equipmentNumber', 'name', 'country', 'street', 'houseNumber', 'postalCode', 'city', 'isRegistrated'];
    dataSourceSystem = new MatTableDataSource<System>();

    applyFilter(filterValue: string) {
        this.dataSourceSystem.filter = filterValue.trim().toLowerCase();
    }

    addSystemsWithDelay(i) {
            const systems = this.systemsBackup.slice(1000 * i, 1000 * i + 1000);
            for (let j = 0; j < systems.length; j++) {
                this.systems.push(systems[j]);
            }
            this.addSystemsToTable();
    }

    addSystemsToTable() {
        if (!this.userInformationService.isVi()) {
            this.dataSourceSystem.data = this.systems.filter(system => system.isRegistrated === '1' || system.isRegistrated === '3');
            this.entries = this.systems.filter(system => system.isRegistrated === '1' || system.isRegistrated === '3').length;
        } else {
            this.dataSourceSystem.data = this.systems;
            this.entries = this.systems.length;
        }
    }

    ngAfterViewInit() {

    }

    compareSystems(a: System, b: System) {
        if (a.equipmentNumber > b.equipmentNumber) {
            return -1;
        }
        if (a.equipmentNumber < b.equipmentNumber) {
            return 1;
        }
        return 0;
    }


    openDetails(row: any) {
        if (row.isRegistrated == 1 || row.isRegistrated == 3 || this.userInformationService.isVi()) {
            this.connectivitySubscription.forEach(s => s.unsubscribe());
            this.registrationSubscription.forEach(s => s.unsubscribe());
            if (this.refreshSubscription) {
                this.refreshSubscription.unsubscribe();
            }
            this.router.navigate(['../details/' + row.equipmentNumber]).then(() => {
            });
        } else {
            this.openNotRegisteredDialog(row, null, null, 'Is not registered');
        }
    }

    openNotRegisteredDialog(row: any, serialNumber: string, headEquipment: string, messageType: string): void {
        const system = row as System;
        this.dialog.open(NotRegistratedPopupComponent, {
            minWidth: '350px',
            data: {
                serialNumber: serialNumber,
                system: system,
                headEquipment: headEquipment,
                messageType: messageType,
            }
        });
    }

    openNotFoundDialog(serialNumber: string): void {
        this.dialog.open(NotFoundPopupComponent, {
            width: '480px',
            data: {
                serialNumber: serialNumber
            }
        });
    }

    ngOnDestroy() {
        this.connectivitySubscription.forEach(s => s.unsubscribe());
        this.registrationSubscription.forEach(s => s.unsubscribe());
        if (this.refreshSubscription) {
            this.refreshSubscription.unsubscribe();
        }
    }

    getHeadEquipment(event: any) {
        this.userInformationService.serialSearch =event.target.value;
        if (event.target.name === 'serialNumberSearch') {
            this.systemDetailService.getHeadEquipment(event.target.value, this.userInformationService.shownCustomer).subscribe(resp => {
                if (resp.info === 'Serialnumber does not exist') {
                    this.openNotFoundDialog(event.target.value);
                } else if (resp.info === 'Headequipment empty') {
                    this.openNotRegisteredDialog(null, event.target.value, null, 'Headequipment empty');
                } else if (resp.info === 'Is not AG') {
                    this.openNotRegisteredDialog(null, event.target.value, resp.headEquipment, 'Is not AG');
                } else if (resp.info === 'Is not registered') {
                    this.openNotRegisteredDialog(null, event.target.value, null, 'Is not registered');
                } else {
                    this.connectivitySubscription.forEach(s => s.unsubscribe());
                    this.registrationSubscription.forEach(s => s.unsubscribe());
                    if (this.refreshSubscription) {
                        this.refreshSubscription.unsubscribe();
                    }
                    this.router.navigate(['../details/' + resp.headEquipment]).then(() => {
                    });
                }
            });
        }
    }

    getFioriUrl(fioriUrl: string, equipmentNumber: string): string {
        return fioriUrl.replace(/\{equipment\}/g, equipmentNumber);
    }
}
