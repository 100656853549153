import {Component, Input, OnInit} from '@angular/core';
import {FeedbackDialogComponent} from '../feedback-dialog/feedback-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {SystemsOverviewService} from '../../services/systems-overview.service';
import {UserInformationService} from '../../services/user-information.service';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../../services/auth.service';
import {delay, map} from 'rxjs/operators';
import {error} from 'protractor';
import {environment} from '../../../environments/environment';


@Component({
    selector: 'vi-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {


    constructor(public dialog: MatDialog, public systemsOverviewService: SystemsOverviewService,
                public userInformationService: UserInformationService, public auth: AuthService) {
        this.authenticated$ = this.auth.csrfToken$.pipe(
            delay(10),
            map((token) => {
                return !!token;
            })
        );
    }

    authenticated$: Observable<boolean>;

    ngOnInit(): void {
    }

    openFeedbackDialog() {
        if (this.userInformationService.country === 'AT') {
            window.open(environment.atPartnerPortalSupportLink, '_blank');
        } else {
            const dialogRef = this.dialog.open(FeedbackDialogComponent, {
                width: '550px',
                data: {name: 'Feedback'}
            });

            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.systemsOverviewService.sendEmail(result).subscribe();
                }
            });
        }
    }

    logout() {
        localStorage.clear();
        this.userInformationService.loggedIn = false;
    }
}
