import {Component, OnInit, OnDestroy, Input, ViewChild} from '@angular/core';
import {Contract} from "../../../models/contract.model";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {SystemDetail} from '../../../models/system-detail.model';
import {environment} from '../../../../environments/environment';
import {UserInformationService} from '../../../services/user-information.service';


@Component({
    selector: 'vi-system-contracts',
    templateUrl: './contracts.component.html',
    styleUrls: ['./contracts.component.scss']
})
export class ContractsComponent implements OnInit, OnDestroy {

    @Input() equipmentContracts: Contract[];
    @Input() systemDetails: SystemDetail;
    displayedColumnsContract: string[] = ['contractNumber', 'posNumber', 'description', 'runTimeAndEqui', 'hasAttachment', 'partnerPortal'];
    dataSourceContract = new MatTableDataSource<Contract>();
    entries: number;
    private paginator: MatPaginator;
    partnerPortalUrl = environment.partnerPortalUrl;
    partnerPortalDEForm = environment.partnerPortalDEFormGeneral;
    partnerPortalNLForm = environment.partnerPortalNLFormGeneral;
    partnerPortalFRForm = environment.partnerPortalFRFormGeneral;
    partnerPortalITForm = environment.partnerPortalITFormGeneral;
    @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
        this.paginator = mp;
        this.setDataSourceAttributes();
    }

    constructor(public userInformationService: UserInformationService) {
    }

    setDataSourceAttributes() {
        this.dataSourceContract.paginator = this.paginator;
    }

    ngOnInit() {
        this.dataSourceContract.data = this.equipmentContracts;
        this.entries = this.equipmentContracts.length;
        this.formatPartnerPortalUrl();
    }

    ngOnDestroy(): void {

    }

    formatPartnerPortalUrl() {
        this.partnerPortalUrl = this.partnerPortalUrl.replace('{languageCode}', this.userInformationService.language)
            .replace('{countryCode}', this.userInformationService.country.toLowerCase());
        if (this.userInformationService.country === 'BE') {
            if (this.userInformationService.language === 'nl') {
                this.partnerPortalUrl = this.partnerPortalUrl.concat(this.partnerPortalNLForm);
            } else if (this.userInformationService.language === 'fr') {
                this.partnerPortalUrl = this.partnerPortalUrl.concat(this.partnerPortalFRForm);
            }
        } else if (this.userInformationService.country === 'DE')    {
            this.partnerPortalUrl = this.partnerPortalUrl.concat(this.partnerPortalDEForm);
        } else if (this.userInformationService.country === 'IT') {
            this.partnerPortalUrl = this.partnerPortalUrl.concat(this.partnerPortalITForm);
        }
    }

}
