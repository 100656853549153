
    <mat-card-title> {{ 'detailsPage.equipment.equipment' | translate }}</mat-card-title>
    <mat-card-content>
        <table>
            <tr>
                <th class="table-font table-width-50">{{ 'detailsPage.equipment.id' | translate }}</th>
                <th class="table-font table-width-50">{{equipmentInformation.equipmentNumber}}</th>
            </tr>
            <tr>
                <th class="table-font table-width-50">{{ 'detailsPage.equipment.name' | translate }}</th>
                <th class="table-font table-width-50">{{equipmentInformation.description}}</th>
            </tr>
        </table>
        <table>
            <tr>
                <td class="table-font table-width-50">{{ 'buttons.certificateDownload' | translate }}</td>
                <td class="attachment" style="color: #ff0000; vertical-align: bottom;"><mat-icon (click)="openCertificate()">
                    picture_as_pdf
                </mat-icon></td>
            </tr>
        </table>
        <!--table>
            <tr>
                <td class="table-font table-width-50">{{ 'buttons.maintenanceProofDownload' | translate }}</td>
                <td class="attachment" style="color: #ff0000; vertical-align: bottom;"><mat-icon (click)="openMaintenanceProof()">
                    picture_as_pdf
                </mat-icon></td>
            </tr>
        </table-->
        <table>
            <tr *ngFor="let scheme of schemes; index as i" >
                <td class="table-font table-width-50"><ng-container *ngIf="i === 0">{{ 'detailsPage.equipment.schemesID' | translate }}</ng-container></td>
                <td class="table-font">{{scheme.documentNumber}}</td>
                <td class="attachment" style="color: #ff0000; vertical-align: bottom;"><mat-icon (click)="openScheme(scheme)">
                    picture_as_pdf
                </mat-icon></td>
            </tr>
        </table>
    </mat-card-content>

    <app-progress-spinner2 class="example-margin" [value]="value" [backdropEnabled]="true" [positionGloballyCenter]="true"
                          [displayProgressSpinner]="displayProgressSpinner">
    </app-progress-spinner2>
