import { System } from '../models/system-overview.model';
import {environment} from "../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable, of, throwError} from 'rxjs';
import {SystemDetail} from "../models/system-detail.model";
import {catchError, tap} from "rxjs/operators";
import {FeedbackInfo} from '../models/feedback.model';

const API_URL = environment.apiUrl;

@Injectable({
    providedIn: 'root'
})
export class SystemsOverviewService {

    constructor(private http: HttpClient){
    }

    public getByCustomerid(id): Observable<System[]> {

        let url = API_URL + '/equi-list/' + id;
        console.log('SystemsOverviewService: ' + url);

        return this.http.get<System[]>(url);
    }

    public async getRegistrationStatus(id): Promise<number> {

        let url = API_URL + '/equi-list/' + id + '/registrationStatus';
        console.log('SystemsOverviewService: ' + url);

        return this.http.get<number>(url).toPromise();
    }

    sendEmail(feedbackInfo: FeedbackInfo): Observable<any> {

        const url = API_URL + '/mail/feedback';
        console.log('SystemsOverviewService: ' + url, feedbackInfo);

        let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', }), responseType: 'text' as 'json' };
        return this.http.post<any>(url, feedbackInfo,  httpOptions);
    }

}
