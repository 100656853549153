<mat-card class="custom-elevation">
    <ng-container *ngFor="let role of partner.role; let i = index">
        <mat-card-title fxLayout="row" fxLayoutAlign="space-between"> <div fxLayout="row"> {{ 'detailsPage.partner.' + role | translate }}
            <div *ngIf="partner.role.length > 1 && i + 1 < partner.role.length">/</div></div>
            <div *ngIf="i == 0 &&
            ((this.userInformationService.isKuMA() && partner.customerNumber === this.userInformationService.user.customerId &&
            this.userInformationService.checkCeoOrAdmin())
            ||
            (this.userInformationService.isEK() && partner.customerNumber === this.userInformationService.user.customerId &&
            partner.eMail === this.userInformationService.user.contacts.email))">
                <a *ngIf="this.userInformationService.isKuMA()" mat-raised-button class="register-mat-icon"
                   href="{{accountContactMaintenanceUrl}}" color="primary">
                    <mat-icon aria-hidden="false" aria-label="edit"
                              style="width: 24px; height: 24px; vertical-align: middle;">
                        mode_edit
                    </mat-icon>
                </a>
                <a *ngIf="this.userInformationService.isEK()" mat-raised-button class="register-mat-icon"
                   href="{{accountMaintenanceUrl}}" color="primary">
                    <mat-icon aria-hidden="false" aria-label="edit"
                              style="width: 24px; height: 24px; vertical-align: middle;">
                        mode_edit
                    </mat-icon>
                </a>
            </div>
        </mat-card-title>
    </ng-container>
    <mat-card-content>
        <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxFlex>
            <div fxFlex>
                <div fxLayout="row">
                    <div class="table-left">{{ 'detailsPage.partner.name' | translate }}</div>
                    <div class="table-left">{{partner.name}}</div>
                </div>
                <div fxLayout="row">
                    <div class="table-left">{{ 'detailsPage.partner.address' | translate }}</div>
                    <div class="table-left">
                        <ng-container *ngIf="this.userInformationService.country !== 'CA' && this.userInformationService.country !== 'US'">{{partner.street}} {{partner.streetNumber}} </ng-container>
                        <ng-container *ngIf="this.userInformationService.country === 'CA' || this.userInformationService.country === 'US'">{{partner.streetNumber}} {{partner.street}}<span *ngIf="partner.floor">, {{partner.floor}}</span></ng-container>
                    </div>
                </div>
                <div fxLayout="row">
                    <div class="table-left"></div>
                    <div class="table-left">
                        <ng-container *ngIf="this.userInformationService.country !== 'CA' && this.userInformationService.country !== 'US'">{{partner.postalCode}} {{partner.city}}</ng-container>
                        <ng-container *ngIf="this.userInformationService.country === 'CA' || this.userInformationService.country === 'US'"> {{partner.city}}, <span *ngIf="partner.state">{{partner.state}}, </span>{{partner.postalCode}}</ng-container>
                    </div>
                </div>
            </div>
            <div fxFlex>
                <div fxLayout="row">
                    <div class="table-left">{{ 'detailsPage.partner.phone' | translate }}</div>
                    <div class="table-left">{{partner.phone}}</div>
                </div>
                <div fxLayout="row">
                    <div class="table-left">{{ 'detailsPage.partner.mobile' | translate }}</div>
                    <div class="table-left">{{partner.mobilePhone}}</div>
                </div>
                <div fxLayout="row">
                    <div class="table-left">{{ 'detailsPage.partner.mail' | translate }}</div>
                    <div class="table-left">{{partner.eMail}}</div>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>
