import {Component, OnInit} from '@angular/core';
import {MaintenanceService, PlannedMaintenance} from '../../services/maintenance.service';
import {NavigationEnd, Router, RouterEvent} from "@angular/router";
import {filter} from "rxjs/operators";

@Component({
    selector: 'app-maintenance-banner',
    templateUrl: './maintenance-banner.component.html',
    styleUrls: ['./maintenance-banner.component.scss']
})
export class MaintenanceBannerComponent implements OnInit {
    public next_maintenance: PlannedMaintenance;
    welcomePage: boolean;

    constructor(private maintenanceService: MaintenanceService, private router: Router) {
        maintenanceService.fetchStatus().subscribe((status) => {
            this.next_maintenance = status.next_maintenance;
            this.welcomePage = !this.router.url.includes('welcome');
        });
        this.router.events
            .pipe(
                filter( event => event instanceof NavigationEnd)
            )
            .subscribe(
                (event: NavigationEnd) => {
                    this.welcomePage = !this.router.url.includes('welcome');
                }
            );
    }

    ngOnInit(): void {
    }

}
