import { Component, OnInit } from '@angular/core';
import {MaintenanceService, PlannedMaintenance} from '../../services/maintenance.service';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {
  public type: string;
  public isLoading = true;
  public next_maintenance: PlannedMaintenance;

  constructor(private maintenanceService: MaintenanceService) {
    maintenanceService.fetchStatus().subscribe((status) => {
      this.type = status.status.toUpperCase();
      this.next_maintenance = status.next_maintenance;
      this.isLoading = false;
    });
  }

  ngOnInit(): void {
  }

}
