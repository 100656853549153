<mat-menu #attachmentMenu="matMenu">
    <button mat-menu-item *ngFor="let attachment of attachments"
            (click)="openPDFGOS(this.systemDetails.equipment.equipmentNumber, attachment)">
        <span *ngIf="attachment.descr != null">{{ attachment.descr }}</span>
        <span *ngIf="attachment.descr == null">{{ attachment.filename }}</span>
    </button>
</mat-menu>
<mat-icon class="attachment" aria-hidden="false" aria-label="attachments"
          style="color: #ff0000; vertical-align: bottom;"
          [matMenuTriggerFor]="attachmentMenu" #menuTrigger="matMenuTrigger"
          (click)="getOfferAttachments(this.systemDetails.equipment.equipmentNumber, contractItem.contractNumber, 'D')">
    picture_as_pdf
</mat-icon>
