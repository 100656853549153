import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'vi-country-not-supported',
    templateUrl: './country-not-supported.component.html',
    styleUrls: ['./country-not-supported.component.scss']
})
export class CountryNotSupportedComponent implements OnInit{

    constructor(){}

    ngOnInit(){

    }
}
