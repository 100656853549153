import { Injectable } from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationExtras, ActivatedRoute} from '@angular/router';
import {Observable, of} from "rxjs";
import {catchError, map, tap, concatMap} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {User} from "../../models/user.model";
import {SystemDetailService} from "../../services/system-detail.service";
import {environment} from '../../../environments/environment';
import {CsrfTokenService} from '../../services/csrf-token.service';
import {UserInformationService} from '../../services/user-information.service';
import {AuthService} from '../../services/auth.service';


@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private route: ActivatedRoute, private auth: AuthService, private csrfTokenService: CsrfTokenService) {
    }


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        if(this.auth.hasValidCsrfToken()) {
            return true;
        }

        // start csrf token authentication process
        return this.auth.fetchNewCsrfToken().pipe(map(res => !!res.token));

    }

}
