
    <mat-card-title> {{ 'detailsPage.location.location' | translate }}</mat-card-title>
    <mat-card-content>
        <table>
            <tr>
                <td colspan="2" class="table-left">{{equipmentLocation.name}}</td>
            </tr>
            <tr>
                <td colspan="2" class="table-left">
                    <ng-container *ngIf="this.userInformationService.country !== 'CA' && this.userInformationService.country !== 'US'">{{equipmentLocation.street}} {{equipmentLocation.houseNumber}} <span *ngIf="equipmentLocation.floor">- {{ 'detailsPage.location.level' | translate }}: {{equipmentLocation.floor}}</span></ng-container>
                    <ng-container *ngIf="this.userInformationService.country === 'CA' || this.userInformationService.country === 'US'">{{equipmentLocation.houseNumber}} {{equipmentLocation.street}}<span *ngIf="equipmentLocation.roomNumber">, {{ 'detailsPage.location.apartment' | translate }} {{equipmentLocation.roomNumber}}</span></ng-container>
                </td>
            </tr>
            <tr>
                <td colspan="2" class="table-left">
                    <ng-container *ngIf="this.userInformationService.country !== 'CA' && this.userInformationService.country !== 'US'">{{equipmentLocation.zipCode}} {{equipmentLocation.city}} <span *ngIf="equipmentLocation.city2">- {{equipmentLocation.city2}}</span></ng-container>
                    <ng-container *ngIf="this.userInformationService.country === 'CA' || this.userInformationService.country === 'US'"> {{equipmentLocation.city}}<span *ngIf="equipmentLocation.city2">- {{equipmentLocation.city2}}</span>, <span *ngIf="equipmentLocation.state">{{equipmentLocation.state}}, </span>{{equipmentLocation.zipCode}}</ng-container>
                </td>
            </tr>
            <tr *ngIf="this.userInformationService.country !== 'CA' && this.userInformationService.country !== 'US'">
                <td class="table-left">{{ 'detailsPage.location.phoneAbbrev' | translate }}:</td>
                <td class="table-left">{{equipmentLocation.phone}}</td>
            </tr>
            <tr *ngIf="this.userInformationService.country !== 'CA' && this.userInformationService.country !== 'US'">
                <td class="table-left">{{ 'detailsPage.location.mobile' | translate }}:</td>
                <td class="table-left">{{equipmentLocation.mobil}}</td>
            </tr>
            <tr *ngIf="this.userInformationService.country !== 'CA' && this.userInformationService.country !== 'US'">
                <td class="table-left">{{ 'detailsPage.location.mail' | translate }}:</td>
                <td class="table-left">{{equipmentLocation.eMail}}</td>
            </tr>
        </table>
    </mat-card-content>

