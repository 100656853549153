<div fxLayout="column" style="margin-bottom: 16px">
    <mat-dialog-content>
        <div fxLayout="row" fxLayout.lt-sm="column">
            <div class="text">
                <div *ngIf="data.messageType == 'Headequipment empty'" [innerHTML]="'notRegisteredDialog.noEquiFound' | translate"></div>
                <div *ngIf="data.messageType == 'Is not registered'" [innerHTML]="'notRegisteredDialog.notFullyRegistered' | translate"></div>
                <div *ngIf="data.messageType == 'Is not AG'" [innerHTML]="'notRegisteredDialog.notClientOne' | translate"></div>
            </div>
        </div>
    </mat-dialog-content>
</div>
<div mat-dialog-actions class="button-row">
    <button mat-stroked-button color="primary" (click)="close()">{{ 'buttons.cancel' | translate }}</button>
    <div *ngIf="!this.userInformationService.isVi()">
        <div *ngIf="data.system !== null">
            <a mat-raised-button
               href="{{heatingSystemRegistrationEquiUrl.replace('{systemId}', data.system.equipmentNumber)}}"
               color="primary">
                {{ 'notRegisteredDialog.changeEqui' | translate }}
            </a>
        </div>
        <div *ngIf="data.serialNumber !== null">
            <a mat-raised-button *ngIf="data.messageType == 'Is not AG'"
               href="{{heatingSystemRegistrationSerialNumberUrl.replace('{serialNumber}', data.serialNumber)}}"
               color="primary">
                {{ 'notRegisteredDialog.transferEqui' | translate }}
            </a>
            <a mat-raised-button *ngIf="data.messageType == 'Headequipment empty'"
               href="{{heatingSystemRegistrationSerialNumberUrl.replace('{serialNumber}', data.serialNumber)}}"
               color="primary">
                {{ 'notRegisteredDialog.registerEqui' | translate }}
            </a>
            <a mat-raised-button *ngIf="data.messageType == 'Is not registered'"
               href="{{heatingSystemRegistrationSerialNumberUrl.replace('{serialNumber}', data.serialNumber)}}"
               color="primary">
                {{ 'notRegisteredDialog.changeEqui' | translate }}
            </a>
        </div>
    </div>
</div>
