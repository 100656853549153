import {Injectable} from '@angular/core';
import {Country} from '../models/country.model';
import {TranslateService} from '@ngx-translate/core';
import {User} from '../models/user.model';


@Injectable()
export class TranslationConfigService {
    private userLanguage: string;
    private defaultLanguage = 'en';
    private readonly defaultLocale = 'en-GB';
    private supportedLanguages = [
    ];
    private supportedCountries = [

    ];
    private countries: Country[];

    private user: User;

    constructor(private translateService: TranslateService) {

    }

    public setUser(user: User) {
        this.user = user;
    }

    public setAvailableCountries(countries: Country[]) {
        this.countries = countries;
        for (let i = 0; i < this.countries.length; i++) {
            for (let j = 0; j < this.countries[i].languages.length; j++) {
                if (this.supportedLanguages.indexOf(this.countries[i].languages[j]) == -1) {
                    this.supportedLanguages.push(this.countries[i].languages[j]);
                }
                if (this.supportedLanguages.indexOf(this.countries[i].languages[j] + '-' + this.countries[i].country) == -1) {
                    this.supportedLanguages.push(this.countries[i].languages[j] + '-' + this.countries[i].country);
                }
            }
            if (this.supportedCountries.indexOf(this.countries[i].country) == -1) {
                this.supportedCountries.push(this.countries[i].country);
            }
        }
    }

    public getDefaultLanguage() {
        return this.defaultLanguage;
    }

    public getSupportedLanguages() {
        return this.supportedLanguages;
    }

    public getSupportedCountries() {
        return this.supportedCountries;
    }

    public setUserLanguage(userLanguage: string) {
        console.log('try setting userlang', userLanguage);
        let supportedLanguage = this.supportedLanguages.find(language => {
            return language === userLanguage;
        });
        if (supportedLanguage === undefined && userLanguage.indexOf('-') > -1) {
            supportedLanguage = this.supportedLanguages.find(language => {
                return language === userLanguage.split('-')[0];
            });
        }
        this.userLanguage = supportedLanguage ? supportedLanguage : this.defaultLanguage;
    }

    public getUserLanguage(): string {
        return this.userLanguage;
    }

    public getLocale(): string {
        if (this.user) {
            if (this.user.locale && this.user.locale.length === 5) {
                return this.coerceToLocale(this.user.locale);
            }
            const language = this.user.languageCode || this.translateService.getBrowserLang();
            const country = this.user.countryCode || this.user.address?.countryCode || this.user.company?.countryCode;
            return this.coerceToLocale(language + '-' + country);
        }
        return this.coerceToLocale(this.translateService.getBrowserCultureLang());
    }

    private coerceToLocale(locale: string): string {
        const [language, country] = locale.split('-');
        if (language && country) {
            return `${language.toLowerCase()}-${country.toUpperCase()}`;
        }
        return this.defaultLocale;
    }
}

