import {TranslateLoader} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';

export class FallbackTranslateHttpLoader implements TranslateLoader {
    constructor(private http: HttpClient,
                public prefix: string = "/assets/i18n/",
                public suffix: string = ".json") {
        console.log('FallbackTranslateHttpLoader');
    }

    public getTranslation(lang: string): Observable<Object> {
        const web = `${this.prefix}${lang}${this.suffix}`;
        const su = '.json';
        const fallback1 = `${this.prefix}${lang.indexOf('-') > 0 ? lang.split('-')[0] : lang}${this.suffix}`;//`./assets/i18n/${lang.indexOf('-') > 0 ? lang.split('-')[0] : lang}${su}`;
        const fallback2 = `${this.prefix}${'en'}${this.suffix}`;

        console.log('web', web);
        console.log('fallback', fallback1);
        console.log('fallback2', fallback2);

        return this.http.get(web).pipe(catchError(() => this.http.get(fallback1).pipe(catchError(() => this.http.get(fallback2)))));
    }
}
