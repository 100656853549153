import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CsrfTokenService} from '../services/csrf-token.service';
import {Router} from '@angular/router';
import {catchError, first, switchMap, tap} from 'rxjs/operators';
import {AuthService} from '../services/auth.service';
import {UserInformationService} from '../services/user-information.service';

@Injectable()
export class CSRFTokenInterceptor implements HttpInterceptor {
    constructor(private auth: AuthService, private userInformationService: UserInformationService) {
    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<HttpEventType.Response>> {

        // Don't intercept for following paths
        const paths = [
            'summary', 'phrase', 'translations', 'assets/', 'available-countries', 'country-by-timezonecity'
        ];

        if (paths.filter(path => request.url.includes(path)).length > 0) {
            return next.handle(request);
        }

        if (paths.filter(path => location.href.includes(path)).length > 0) {
            return next.handle(request);
        }

        if (!this.userInformationService.loggedIn && request.url.includes('feedback'))
            return next.handle(request);


        const requestHeaders = {};
        let withCredentials: boolean;
        request.headers.keys().forEach((key) => {
            requestHeaders[key] = request.headers.get(key);
        });

        const headers = {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Accept-Language':  window.localStorage.getItem('locale') || navigator.language,
            ...requestHeaders,
        };

        // get CRSF token from local storage
        const token = localStorage.getItem('fp_csrf_token');

        if (token && !request.url.endsWith('/saml/csrf')) {
            headers['Authorization'] = `CSRF ${token}`;
        }

        // use cookies only for endpoint .../saml/csrf
        withCredentials = request.url.endsWith('/saml/csrf');

        request = request.clone({
            setHeaders: headers,
            withCredentials: withCredentials,
        });

        return this.auth.csrfToken$.pipe(
            first((val) => val !== undefined),
            switchMap((value) =>
                next.handle(request.clone({headers: request.headers.set('Authorization', `CSRF ${value}`)})).pipe(
                    catchError((e) => {
                        if (e instanceof HttpErrorResponse && e.status === 401) {
                            // refresh CSRF token and retry active request if fetching of token succeed
                            return this.auth.fetchNewCsrfToken().pipe(switchMap(() => this.intercept(request, next)));
                        }
                        throw e;
                    })
                )
            )
        );
    }
}
