import {Component, OnInit, OnDestroy, Input} from '@angular/core';
import {Location} from '../../../models/location.model';
import {UserInformationService} from '../../../services/user-information.service';


@Component({
    selector: 'vi-system-location',
    templateUrl: './location.component.html',
    styleUrls: ['./location.component.scss']
})
export class LocationComponent implements OnInit, OnDestroy {

    @Input() equipmentLocation: Location;
    constructor(public userInformationService: UserInformationService) {
    }

    ngOnInit() {
    }

    ngOnDestroy(): void {

    }

}
