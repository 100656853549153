<div>
    <div class="cdk-overlay-pane" style="max-width: 80vw; pointer-events: auto; position: static">
        <mat-dialog-content class="universal-dialog-content mat-dialog-content" style="width: 300px;">
            <div style="flex-direction: row; box-sizing: border-box; display: flex">
                <div style="flex: 1 1 100%; box-sizing: border-box; max-width: 80%">
                    <mat-form-field style="width: 260px">
                        <input type="text" placeholder="E-Mail" aria-label="Number" [disabled]="true" matInput [ngModel]="this.email">
                    </mat-form-field>
                    <!--<mat-form-field style="width: 260px">
                        <input type="text" placeholder="E-Mail" aria-label="Number" matInput [formControl]="formControl" [matAutocomplete]="auto" (keyup)="setEmail($event)">
                    </mat-form-field>
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let option of filteredEmails | async" [value]="option" (onSelectionChange)="email = option">
                            {{option}}
                        </mat-option>
                    </mat-autocomplete>-->

                </div>
            </div>
        </mat-dialog-content>
    </div>
    <mat-dialog-actions class="mat-dialog-actions">
        <button color="primary" mat-raised-button type="button" mat-dialog-close>
            <span class="mat-button-wrapper">{{ 'buttons.cancel' | translate }}</span>
        </button>
        <div fxFlex></div>
        <button color="primary" mat-raised-button type="button" (click)="sendCertificate()">
            <span class="mat-button-wrapper">{{ 'buttons.send' | translate }}</span>
        </button>
    </mat-dialog-actions>
</div>
