import {Component, OnInit, OnDestroy, Input, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {CertificateService} from '../../../services/certificate.service';
import {FormControl} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UserInformationService} from '../../../services/user-information.service';
import {TranslateService} from '@ngx-translate/core';


@Component({
    selector: 'vi-certificate',
    templateUrl: './certificate.component.html',
    styleUrls: ['./certificate.component.scss']
})
export class CertificateComponent implements OnInit, OnDestroy {

    email: string;
    equipmentNumber: string;
    status: boolean;
    emails: string[];
    filteredEmails: Observable<string[]>;
    //formControl = new FormControl();
    validateEmail = '[a-zA-Z0-9._-]+[@]+[a-zA-Z0-9-]+[.]+[a-zA-Z]{2,6}';

    constructor(public dialogRef: MatDialogRef<CertificateComponent>,@Inject(MAT_DIALOG_DATA) public data: any,
                private certificateService: CertificateService, private _snackBar: MatSnackBar, private userInformationService: UserInformationService,
                private translateService: TranslateService) {
    }

    ngOnInit() {
        this.equipmentNumber = this.data.equipmentNumber;
        //this.emails = this.data.emails;
        this.email = this.data.emails;
        /*this.filteredEmails = this.formControl.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value))
        );*/
    }

    ngOnDestroy(): void {

    }

    sendCertificate() {
        if (this.email.match(this.validateEmail)) {
            let mailType;
            if (this.userInformationService.isVi() ||
                this.userInformationService.isKuMA()) {
                mailType = 'C';
            } else if (this.userInformationService.isEK()) {
                mailType = 'B';
            }
            this.certificateService.sendCertificate(mailType, this.equipmentNumber, this.email, this.userInformationService.user.languageCode)
                .subscribe(data => {
                    this.status = data;
                });
            this.dialogRef.close();
        } else {
            this._snackBar.open(this.translateService.instant('alert.invalidMail'), '', {
                duration: 2000,
            });
        }
    }

    setEmail(event: any) {
        this.email = event.target.value;
    }

    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();

        return this.emails.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
    }


}
