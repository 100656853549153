import {Component, Inject, OnInit} from '@angular/core';
import {FeedbackInfo} from '../../models/feedback.model';
import {UserInformationService} from '../../services/user-information.service';
import {User} from '../../models/user.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";


@Component({
    selector: 'app-feedback-dialog',
    templateUrl: './feedback-dialog.component.html',
    styleUrls: ['./feedback-dialog.component.scss']
})
export class FeedbackDialogComponent implements OnInit {
    feedbackForm: FormGroup;

    constructor( public dialogRef: MatDialogRef<FeedbackDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: string,
                 public userInformationService: UserInformationService, private formBuilder: FormBuilder) {
    }

    ngOnInit() {
        this.feedbackForm = this.formBuilder.group({
            mailFrom: ['', Validators.required],
            mailSubject: ['', Validators.required],
            mailSystemOrComponent: ['', Validators.required],
            mailBody: ['', Validators.required]
        });
        if (this.userInformationService.loggedIn) {
            this.feedbackForm.get('mailFrom').setValue(this.userInformationService.user.contacts.email);
            if (this.userInformationService.shownEquipment !== null) {
                this.feedbackForm.get('mailSystemOrComponent').setValue(this.userInformationService.shownEquipment);
            }
        }
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    getFeedbackData() {
        this.feedbackForm.markAllAsTouched();
        if (this.feedbackForm.valid) {
            const fb = new FeedbackInfo();
            fb.mailBody = this.feedbackForm.get('mailBody').value;
            fb.mailFrom =  this.feedbackForm.get('mailFrom').value;
            fb.mailSubject = this.feedbackForm.get('mailSubject').value;
            fb.mailSystemOrComponent = this.feedbackForm.get('mailSystemOrComponent').value;
            this.dialogRef.close(fb);
        }
    }
}
