import {Component, Input} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {UserInformationService} from '../../services/user-information.service';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'vi-equi-not-fully-registered',
    templateUrl: './equi-not-fully-registered.component.html',
    styleUrls: ['./equi-not-fully-registered.component.scss']
})
export class EquiNotFullyRegisteredComponent {


    constructor(private activatedRoute: ActivatedRoute, private router: Router, public dialog: MatDialog,
                public userInformationService: UserInformationService) {
    }

    private subscription: Subscription;
    equipmentNumber: number;
    customerID: number;
    @Input() serialNumber: string;
    dataProtectionDeclarationUrl = environment.dataProtection.split('{b2x}');
    heatingSystemRegistrationEquiUrl = environment.heatingSystemRegistrationEqui;

    ngOnInit() {
    this.subscription = this.activatedRoute.params.subscribe(
    params => {
        this.equipmentNumber = +params['equinumber'];
        this.customerID = +params['customerID'];
        });
    }


        back(){
            this.router.navigate(['../list']);
        }
}
