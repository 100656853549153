<div class="custom-elevation" fxFlex="row">
    <div fxFlex="0 1 50%">
        <img mat-card-image src="../../../assets/images/no-installation.png"
             style="padding: 29px 27px 34px; max-width: 245px;">
    </div>
    <div fxFlex="column" style="padding-bottom: 16px">
        <div class="text-div" *ngIf="!this.userInformationService.isEK()">
            {{ 'detailsPage.notAllowedBusiness' | translate }}
        </div>
        <div class="text-div" *ngIf="this.userInformationService.isEK()">
            {{ 'detailsPage.notAllowedEndCustomer' | translate }}
        </div>
        <a *ngIf="structure.serialNumber" mat-raised-button style="margin-left: 48px"
           href="{{heatingSystemRegistrationSerialNumberUrl.replace('{serialNumber}', structure.serialNumber)}}"
           color="primary">{{ 'button.takeOverInstallation' | translate }}
        </a>
    </div>
</div>
