import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatMenu, MatMenuTrigger} from '@angular/material/menu';
import {SystemDetailService} from '../../../../services/system-detail.service';
import {UserInformationService} from '../../../../services/user-information.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Attachment} from '../../../../models/attachment.model';
import {SystemDetail} from '../../../../models/system-detail.model';
import {History} from '../../../../models/history.model';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'vi-pdf-menu',
    templateUrl: './pdf-menu.component.html',
    styleUrls: ['./pdf-menu.component.scss']
})
export class PdfMenuComponent {

    constructor(private systemDetailService: SystemDetailService, private userInformationService: UserInformationService,
                private _snackBar: MatSnackBar, private translateService: TranslateService) {
    }

    attachments: Attachment[] = [];
    @Input() systemDetails: SystemDetail;
    @Input() historyItem: History;
    @ViewChild('menuTrigger') clickHoverMenuTrigger: MatMenuTrigger;
    @ViewChild('attachmentMenu') matMenu: MatMenu;


    getOrderAttachments(id, order, language) {
        this.closeMatMenu();
        if (this.attachments.length === 0) {
            this.systemDetailService.getOrderAttachments(id, order, language, this.userInformationService.shownCustomer).subscribe((attachments) => {
                this.attachments = attachments;
                if (this.attachments.length === 1) {
                    this.openPDFGOS(this.systemDetails.equipment.equipmentNumber, this.attachments[0]);
                } else if (this.attachments.length > 1) {
                    this.openMatMenu();
                } else {
                    this._snackBar.open(this.translateService.instant('alert.noServiceReportForOrder'), '', {
                        duration: 2000,
                    });
                }
            });
        } else {
            if (this.attachments.length === 1) {
                this.openPDFGOS(this.systemDetails.equipment.equipmentNumber, this.attachments[0]);
            } else if (this.attachments.length > 1) {
                this.openMatMenu();
            }
        }
    }

    openPDFGOS(id, attachment) {
        this.systemDetailService.getPDFGOS(id, attachment).subscribe((response) => {
            const binaryData = [];
            binaryData.push(response);
            const url = URL.createObjectURL(new Blob(binaryData, {type: 'application/pdf'}));
            window.open(url, '_blank');
        });
    }

    closeMatMenu() {
        this.clickHoverMenuTrigger.closeMenu();
    }

    openMatMenu() {
        this.clickHoverMenuTrigger.openMenu();
    }

    getReportAttachments(id, report, language) {
        this.closeMatMenu();
        if (this.attachments.length === 0) {
            this.systemDetailService.getReportAttachments(id, report, language, this.userInformationService.shownCustomer).subscribe((attachments) => {
                this.attachments = attachments;
                if (this.attachments.length === 1) {
                    this.openPDFGOS(this.systemDetails.equipment.equipmentNumber, this.attachments[0]);
                } else if (this.attachments.length > 1) {
                    this.openMatMenu();
                } else {
                    this._snackBar.open(this.translateService.instant('alert.noServiceReportForOrder'), '', {
                        duration: 2000,
                    });
                }
            });
        } else {
            if (this.attachments.length === 1) {
                this.openPDFGOS(this.systemDetails.equipment.equipmentNumber, this.attachments[0]);
            } else if (this.attachments.length > 1) {
                this.openMatMenu();
            }
        }
    }


    getAttachments() {
        if (this.historyItem.type === '3') {
            this.getReportAttachments(this.systemDetails.equipment.equipmentNumber, this.historyItem.orderNumber, 'D');
        } else {
            this.getOrderAttachments(this.systemDetails.equipment.equipmentNumber, this.historyItem.orderNumber, 'D');
        }
    }
}




