<h1 mat-dialog-title>{{'feedbackdialog.title' | translate}}</h1>
<div mat-dialog-content>
    <div class="example-form" [formGroup]="feedbackForm">
        <mat-form-field class="example-full-width">
            <mat-label>{{'feedbackdialog.mailFrom' | translate}}</mat-label>
            <input matInput required formControlName="mailFrom">
        </mat-form-field>

        <mat-form-field class="example-full-width">
            <mat-label>{{'feedbackdialog.subject' | translate}}</mat-label>
            <input matInput required formControlName="mailSubject">
        </mat-form-field>

        <mat-form-field class="example-full-width">
            <mat-label>{{'feedbackdialog.systemOrComponent' | translate}}</mat-label>
            <input matInput required formControlName="mailSystemOrComponent">
        </mat-form-field>

        <mat-form-field class="example-full-width">
            <mat-label>{{'feedbackdialog.feedbacktext' | translate}}</mat-label>
            <textarea matInput rows="10" placeholder="{{'feedbacktext.placeholder' | translate}}" required formControlName="mailBody"></textarea>
        </mat-form-field>
        <p class="disclaimer">{{'feedbackdialog.disclaimer' | translate}}</p>
    </div>
</div>
<div mat-dialog-actions style="justify-content: space-between">
    <button mat-raised-button color="primary" (click)="onNoClick()">{{'buttons.cancel' | translate }}</button>
    <button mat-raised-button color="primary" (click)="getFeedbackData()" cdkFocusInitial>{{'buttons.sendFeedback' | translate }}</button>
</div>
