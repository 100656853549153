import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserInformationService} from '../../services/user-information.service';
import {SystemDetailService} from '../../services/system-detail.service';
import {System} from '../../models/system-overview.model';
import {NotRegistratedPopupComponent} from '../systems-overview/not-registrated-popup/not-registrated-popup.component';
import {NotFoundPopupComponent} from '../systems-overview/not-found-popup/not-found-popup.component';
import {MatDialog} from '@angular/material/dialog';


@Component({
    selector: 'vi-customer-search',
    templateUrl: './customer-search.component.html',
    styleUrls: ['./customer-search.component.scss']
})
export class CustomerSearchComponent implements OnInit {
    userRole: string;
    equiSearch = '';

    constructor(private router: Router, public userInformationService: UserInformationService, public dialog: MatDialog,
                private systemDetailService: SystemDetailService) {
    }


    ngOnInit(): void {
        this.userRole = this.userInformationService.user.role;
    }


    onKey(event: any) {
        if (event.target.name === 'customerSearch') {
            if (event.target.value.length > 10) {
                event.target.value = event.target.value.slice(0, 10);
            }
            this.userInformationService.shownCustomer = event.target.value;
        } else if (event.target.name === 'equiSearch') {
            this.userInformationService.equiSearch = event.target.value;
        } else if (event.target.name === 'serialSearch') {
            this.userInformationService.serialSearch = event.target.value;
        }
        if (event.key === 'Enter') {
            this.search();
        }
    }

    search() {
        if (this.userInformationService.equiSearch && this.userInformationService.shownCustomer) {
            this.navigateEqui();
        } else if (this.userInformationService.serialSearch && this.userInformationService.shownCustomer) {
            this.navigateEquiBySerial();
        } else if (this.userInformationService.shownCustomer) {
            this.navigateCustomer();
        } else if (this.userInformationService.equiSearch) {
            this.navigateEqui();
        } else if (this.userInformationService.serialSearch) {
            this.navigateEquiBySerial();
        }
    }

    navigateCustomer() {
        this.router.navigate(['/list']);
    }

    navigateEqui() {
        if (this.userInformationService.shownCustomer) {
            this.router.navigate(['/details', this.userInformationService.equiSearch], {queryParams: {customer: this.userInformationService.shownCustomer}});
        } else {
            this.router.navigate(['/details', this.userInformationService.equiSearch]);
        }
    }

    navigateEquiBySerial() {
        this.systemDetailService.getHeadEquipment(this.userInformationService.serialSearch, this.userInformationService.shownCustomer).subscribe(resp => {
            if (resp.info === 'Serialnumber does not exist') {
                this.openNotFoundDialog(this.userInformationService.serialSearch);
            } else if (resp.info === 'Headequipment empty') {
                this.openNotRegisteredDialog(null, this.userInformationService.serialSearch, null, 'Headequipment empty');
            } else {
                if (this.userInformationService.shownCustomer) {
                    this.router.navigate(['../details', resp.headEquipment], {queryParams: {customer: this.userInformationService.shownCustomer}}).then(() => {
                    });
                } else {
                    this.router.navigate(['../details', resp.headEquipment]).then(() => {
                    });
                }
            }
        });
    }

    openNotRegisteredDialog(row: any, serialNumber: string, headEquipment: string, messageType: string): void {
        const system = row as System;
        this.dialog.open(NotRegistratedPopupComponent, {
            minWidth: '350px',
            data: {
                serialNumber: serialNumber,
                system: system,
                headEquipment: headEquipment,
                messageType: messageType,
            }
        });
    }

    openNotFoundDialog(serialNumber: string): void {
        this.dialog.open(NotFoundPopupComponent, {
            width: '480px',
            data: {
                serialNumber: serialNumber
            }
        });
    }
}
