<section *ngIf="next_maintenance && welcomePage" class="section" >
  <div>
      <mat-card class="custom-elevation" style="margin: 0 1rem 1rem">
          <div>
            <div style="display: inline-block; width: 10%;vertical-align: top;"><mat-icon aria-hidden="false" aria-label="Example info icon">info</mat-icon></div>
              <div style="display: inline-block; width: 80%;">{{'COMMON.MAINTENANCE.MESSAGE.PLANNED' | translate: {from:next_maintenance.start_date | date : 'medium', until:next_maintenance.end_date | date : 'medium'} }}</div>
          </div>
      </mat-card>
  </div>
</section>
