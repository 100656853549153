<mat-sidenav-container class="example-container">
    <mat-sidenav-content class="page-wrap">
        <vi-header></vi-header>
        <vi-content class="content" [maxWidth]="'1440px'">
            <app-maintenance-banner></app-maintenance-banner>
            <app-performance-issues-banner></app-performance-issues-banner>
            <router-outlet></router-outlet>
        </vi-content>
        <vi-footer></vi-footer>
    </mat-sidenav-content>
</mat-sidenav-container>
