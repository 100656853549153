import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

import {of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {CsrfTokenService} from '../../services/csrf-token.service';
import {AuthService} from '../../services/auth.service';


@Injectable()
export class WelcomeGuard implements CanActivate {

    constructor(private router: Router, private route: ActivatedRoute, private http: HttpClient,
                private csrfTokenService: CsrfTokenService, private auth: AuthService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        if (this.auth.getCsrfTokenFromUrl()) {
            this.router.navigate(['/list']);
            return false;
        }

        return this.auth.getCsrfTokenRequest().pipe(map(res => {
            this.router.navigate(['/list']);
            return false;
        })).pipe(catchError( err => of(true)));
    }

}
