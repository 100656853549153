<mat-menu #attachmentMenu="matMenu">
    <button mat-menu-item *ngFor="let attachment of attachments"
            (click)="openPDFGOS(systemDetails.equipment.equipmentNumber, attachment)">
        <span *ngIf="attachment.descr != null">{{ attachment.descr }}</span>
        <span *ngIf="attachment.descr == null">{{ attachment.filename }}</span>
    </button>
</mat-menu>
<mat-icon class="attachment" aria-hidden="false" aria-label="attachments"
          [matMenuTriggerFor]="attachmentMenu" #menuTrigger="matMenuTrigger"
          (click)="getAttachments()">
    picture_as_pdf
</mat-icon>
