<div *ngIf="!isLoading">
    <div fxLayout="column">
        <div fxLayout="row" class="page-number">
            <mat-icon (click)="back()">arrow_back</mat-icon>
            <p class="page-number-1" (click)="back()">{{ 'link.overview' | translate }}</p>
            <p *ngIf="systemLoaded |async">&nbsp;/&nbsp;</p>
            <p class="page-number-2" *ngIf="systemLoaded |async">{{systemDetail.equipment.description}}</p>
        </div>
        <vi-customer-search></vi-customer-search>
        <section class="section" fxFlex>
            <div fxLayout="column">
                <div *ngIf="showSpinner">
                    <progress-spinner></progress-spinner>
                </div>
                <div *ngIf="isSEqui |async">
                    <vi-equi-is-s-equi [serialNumber]="this.sEquiSerialNumber"></vi-equi-is-s-equi>
                </div>
                <div *ngIf="systemExists |async">
                    <vi-equi-not-found></vi-equi-not-found>
                </div>
                <div *ngIf="systemAllowed |async">
                    <vi-equi-not-allowed [structure]="systemDetail.structure[0]"></vi-equi-not-allowed>
                </div>
                <div *ngIf="systemNotFullyRegistered |async">
                    <vi-equi-not-fully-registered [serialNumber]="systemDetail.structure && systemDetail.structure.length > 0 ? systemDetail.structure[0].serialNumber : undefined"></vi-equi-not-fully-registered>
                </div>
                <div fxFill fxLayout="row wrap" fxLayoutAlign="space-between stretch">
                    <mat-card class="custom-elevation" style="max-width: 1440px; width: 100%;"
                              *ngIf="systemLoaded |async">
                        <mat-card-content>
                            <div class="containerX">
                                <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxFlex>
                                    <div fxFlex>
                                        <vi-system-equipment
                                            [equipmentInformation]="systemDetail.equipment" [schemes]="systemDetail.schemes"></vi-system-equipment>
                                    </div>
                                    <div fxFlex>
                                        <vi-system-location
                                            [equipmentLocation]="systemDetail.location"></vi-system-location>
                                    </div>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>


                <div>
                    <vi-system-contracts [systemDetails]="systemDetail" [equipmentContracts]="systemDetail.contract"
                                         *ngIf="systemLoaded |async"
                                         style="max-width: 1440px;"></vi-system-contracts>
                </div>

                <div>
                    <vi-system-offers [systemDetails]="systemDetail" [equipmentContracts]="systemDetail.offer"
                                         *ngIf="systemLoaded |async"
                                         style="max-width: 1440px;"></vi-system-offers>
                </div>


                <div>
                    <vi-system-structure [equipmentStructure]="systemDetail.structure"
                                         [unregisteredSystems]="systemDetail.unregisteredSystem"
                                         [vitoguideId]="systemDetail.vitoGuideId" *ngIf="systemLoaded |async"
                                         style="max-width: 1440px;"></vi-system-structure>
                </div>

                <div *ngIf="systemLoaded |async">
                    <div *ngIf="systemDetail.partner.length > 0">
                        <vi-system-partner *ngFor="let partner of systemDetail.partner"
                                           [partner]="partner" style="max-width: 1440px;"></vi-system-partner>
                    </div>
                </div>

                <!--
                        <div *ngIf="systemLoaded |async">
                            <div *ngIf="systemDetail.nextMaintenance.length > 0">
                                <vi-next-maintenance [nextMaintenances]="systemDetail.nextMaintenance"
                                                     *ngIf="systemLoaded |async"></vi-next-maintenance>
                            </div>
                        </div>
                -->

                <div>
                    <vi-system-history [systemDetails]="systemDetail" *ngIf="systemLoaded |async"
                                       style="max-width: 1440px;"></vi-system-history>
                </div>
            </div>

            <div *ngIf="systemLoaded | async" class="button-row">

                <button mat-button (click)="back()" color="primary">
                    {{ 'buttons.back' | translate }}
                </button>

                <div
                    *ngIf="this.userInformationService.registrationLink()">
                    <a mat-raised-button
                       href="{{heatingSystemRegistrationEquiUrl}}"
                       color="primary">
                        {{ 'buttons.changeEqui' | translate }}
                    </a>
                </div>

                <div
                    *ngIf="this.userInformationService.isVi()">
                    <a mat-raised-button
                       href="{{fioriEquipmentUrl}}"
                       color="primary">
                        {{ 'buttons.changeEqui' | translate }}
                    </a>
                </div>

                <div
                    *ngIf="this.userInformationService.user.serviceConfiguratorLink">
                    <a mat-raised-button
                       href="{{serviceConfiguratorUrl}}"
                       color="primary">
                        {{ 'buttons.serviceConfigurator' | translate }}
                    </a>
                </div>

                <a mat-raised-button
                   href="{{viBooksUrl}}"
                   target="_blank" rel="noopener noreferrer"
                   color="primary">
                    {{ 'buttons.viBooks' | translate }}
                </a>

                <div *ngIf="systemDetail.vitoGuideId">
                    <a mat-raised-button
                       href="{{vitoGuideUrl}}"
                       target="_blank" rel="noopener noreferrer"
                       color="primary">
                        {{ 'buttons.vitoguide' | translate }}
                    </a>
                </div>

                <button mat-raised-button (click)="sendCertificate()" color="primary">
                    {{ 'buttons.certificate' | translate }}
                </button>

            </div>

        </section>
    </div>
</div>


<div *ngIf="isLoading">
    <progress-spinner></progress-spinner>
</div>
