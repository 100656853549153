<mat-card class="custom-elevation">
    <mat-card-title class="title"> {{ 'detailsPage.structure.structure' | translate }}
        <mat-icon matTooltip="{{ 'info.WarrantyStartCalculation' | translate }}" class="vi-pointer">info</mat-icon>
    </mat-card-title>
    <mat-card-content
        *ngIf="equipmentStructure.length == 0 && unregisteredSystems.length == 0">{{ 'detailsPage.noEntries' | translate }}
    </mat-card-content>
    <table mat-table [dataSource]="dataSourceStructure" *ngIf="equipmentStructure.length > 0">

        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> {{ 'detailsPage.structure.name' | translate }}</th>
            <td mat-cell *matCellDef="let element"> {{element.description}} </td>
        </ng-container>

        <ng-container matColumnDef="serialNumber">
            <th mat-header-cell *matHeaderCellDef> {{ 'detailsPage.structure.serialnumber' | translate }}</th>
            <td mat-cell *matCellDef="let element">{{element.serialNumber}}</td>
        </ng-container>

        <ng-container matColumnDef="startUpDate">
            <th mat-header-cell *matHeaderCellDef> {{ 'detailsPage.equipment.startUpDate' | translate }}</th>
            <td mat-cell *matCellDef="let element"> {{element.startUpDate}} </td>
        </ng-container>

        <ng-container matColumnDef="warrantyStart">
            <th mat-header-cell *matHeaderCellDef> {{ 'detailsPage.structure.warrantyStart' | translate }}</th>
            <td mat-cell *matCellDef="let element" matTooltip={{element.warrantyText}}> {{element.warrantyStart}} </td>
        </ng-container>

        <ng-container matColumnDef="warrantyEnd">
            <th mat-header-cell *matHeaderCellDef> {{ 'detailsPage.structure.warrantyEnd' | translate }}</th>
            <td mat-cell *matCellDef="let element" matTooltip={{element.warrantyText}}> {{element.warrantyEnd}} </td>
        </ng-container>

        <ng-container matColumnDef="vitoguide">
            <th mat-header-cell *matHeaderCellDef class="icon-column"></th>
            <td mat-cell *matCellDef="let element" class="icon-column">
                <div *ngIf="element.connected===true && !this.userInformationService.isEK()">
                    <a
                        href="{{vitoGuideUrl.replace('{installationId}', element.vitoguideId)}}"
                        target="_blank" rel="noopener noreferrer">
                        <img
                            matTooltip="{{ 'buttons.vitoguide' | translate }}"
                            style="vertical-align: bottom;"
                            fxFlex="30px" width="30px" height="30px"
                            src="assets/images/vitoguide-app-icon.svg" viewBox="0 0 275 60" alt="Vitoguide">
                    </a>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="shop">
            <th mat-header-cell *matHeaderCellDef class="icon-column"></th>
            <td mat-cell *matCellDef="let element" class="icon-column">
                <div *ngIf="checkSerialNumber16(element.serialNumber) && !this.userInformationService.isEK()">
                    <a
                        href="{{shopSerialUrl}}?query={{element.serialNumber.slice(0, 16)}}"
                        target="_blank" rel="noopener noreferrer">
                        <img
                            matTooltip="{{ 'buttons.shop.components' | translate: {nameSerialNumber:element.description}  }}"
                            style="vertical-align: bottom;"
                            fxFlex="30px" width="30px" height="30px"
                            src="assets/images/obs-app-icon.svg" viewBox="0 0 275 60" alt="Viessmann Shop">
                    </a>
                </div>
                <div *ngIf="checkSerialNumber7(element.serialNumber) && !this.userInformationService.isEK()">
                    <a
                        href="{{shopUrl}}{{element.serialNumber.slice(0, 7)}}?selectedDetailsTab=2"
                        target="_blank" rel="noopener noreferrer">
                        <img
                            matTooltip="{{ 'buttons.shop.components' | translate: {nameSerialNumber:element.description}  }}"
                            style="vertical-align: bottom;"
                            fxFlex="30px" width="30px" height="30px"
                            src="assets/images/obs-app-icon.svg" viewBox="0 0 275 60" alt="Viessmann Shop">
                    </a>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="partnerPortal">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="!this.userInformationService.isEK() && this.userInformationService.partnerPortalExists()">
                    <a class="icon-column"
                       href="{{partnerPortalUrl}}?serialNumber={{element.serialNumber}}"
                       target="_blank" rel="noopener noreferrer">
                        <img
                            matTooltip="{{ 'buttons.partnerPortal' | translate }}"
                            style="vertical-align: bottom;"
                            fxFlex="30px" width="30px" height="30px"
                            src="assets/images/marktpartner-portal-app-icon.svg" viewBox="0 0 275 60"
                            alt="Partner Portal">
                    </a>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsStructure"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsStructure;"></tr>
    </table>
    <mat-paginator *ngIf="equipmentStructure.length > 50" [pageSizeOptions]="[50, this.entries]"
                   showFirstLastButtons></mat-paginator>
</mat-card>
