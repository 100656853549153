<div class="landing-page-container image-wrapper" fxLayout fxLayoutAlign="start stretch">
    <div fxFlex fxFlexOffset.gt-sm="20%" class="landing-page-image"></div>
</div>

<!-- MD and above screen sizes -->
<div class="landing-page-container content-wrapper landscape-layout" fxLayout="row" fxLayoutAlign="start center">
    <section class="info-sections-wrapper" fxFlexOffset="5%">
        <div class="info-section">
            <h1 class="title">{{ 'application.title' | translate }}</h1>
            <h4 class="sub-title">{{ 'application.subtitle' | translate }}</h4>

        </div>

        <div class="info-section">
            <div class="info-section" style="padding: 1rem 0 4rem;">
                <ng-container *ngIf="!isUSOrCA">
                    {{ 'welcome.signOnOne' | translate }}
                    <a href="{{iamConfig.auth.baseUrl}}saml/sso/request?appId={{iamConfig.auth.appId}}&redirectUrl={{this.getLocation()}}">
                        <strong>{{ 'link.login' | translate }}</strong>
                    </a>
                    {{ 'welcome.signOnTwo' | translate }}
                </ng-container>
                <ng-container *ngIf="isUSOrCA">
                    {{ 'welcome.signOnUSCA' | translate }}
                    <ng-container *ngIf="country === 'US'">
                        <a href="https://forms.gle/gQoPa35kYDZQhcAB7">
                            <strong>{{ 'welcome.support' | translate }}</strong>
                        </a>
                    </ng-container>
                    <ng-container *ngIf="country === 'CA'">
                        <a href="https://docs.google.com/forms/d/e/1FAIpQLSer80JzbnbKETfaLv9Gi6pONWPac9o-k_iIQpdd-BpKM84UOA/viewform">
                            <strong>{{ 'welcome.support' | translate }}</strong>
                        </a>
                    </ng-container>
                </ng-container>
            </div>
            <ng-container *ngIf="!isUSOrCA">
                {{ 'welcome.createAccount' | translate }}
                <div class="button-row">
                    <div fxLayout="column">
                        <div>
                            <a style="margin-right: 16px" class="register-button" mat-button color="primary"
                               href="{{endCustomerRegistration}}"
                               target="_blank" rel="noopener noreferrer">{{ 'welcome.customerB2C' | translate }}</a>
                        </div>
                        <div>
                            <a class="register-button" mat-button color="primary" href="{{customerRegistration}}"
                               target="_blank" rel="noopener noreferrer">{{ 'welcome.customerB2B' | translate }}</a>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>

    </section>
</div>

<!-- XS / SM screen sizes -->
<div class="landing-page-container content-wrapper text-center portrait-layout" fxLayout="column"
     fxLayoutAlign="stretch center">

    <header class="info-section" fxFlex="1 1">
        <h1 class="title">{{ 'application.title' | translate }}</h1>
        <h4 class="sub-title">{{ 'application.subtitle' | translate }}</h4>
    </header>

    <section class="info-section description" fxFlex>
        <div class="info-section" style="padding: 20rem 0 4rem;">
            <ng-container *ngIf="!isUSOrCA">
                {{ 'welcome.signOnOne' | translate }}
                <a href="{{iamConfig.auth.baseUrl}}saml/sso/request?appId={{iamConfig.auth.appId}}&redirectUrl={{this.getLocation()}}">
                    <strong>{{ 'link.login' | translate }}</strong>
                </a>
                {{ 'welcome.signOnTwo' | translate }}
            </ng-container>
            <ng-container *ngIf="isUSOrCA">
                {{ 'welcome.signOnUSCA' | translate }}
                <ng-container *ngIf="country === 'US'">
                    <a href="https://forms.gle/gQoPa35kYDZQhcAB7">
                        <strong>{{ 'welcome.support' | translate }}</strong>
                    </a>
                </ng-container>
                <ng-container *ngIf="country === 'CA'">
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSer80JzbnbKETfaLv9Gi6pONWPac9o-k_iIQpdd-BpKM84UOA/viewform">
                        <strong>{{ 'welcome.support' | translate }}</strong>
                    </a>
                </ng-container>
            </ng-container>
        </div>
        <ng-container *ngIf="!isUSOrCA">
            {{ 'welcome.createAccount' | translate }}
            <div class="button-row">
                <div fxLayout="column">
                    <div>
                        <a style="margin-right: 16px" class="register-button" mat-button color="primary"
                           href="{{endCustomerRegistration}}"
                           target="_blank" rel="noopener noreferrer">{{ 'welcome.customerB2C' | translate }}</a>
                    </div>
                    <div>
                        <a class="register-button" mat-button color="primary" href="{{customerRegistration}}"
                           target="_blank" rel="noopener noreferrer">{{ 'welcome.customerB2B' | translate }}</a>
                    </div>
                </div>
            </div>
        </ng-container>
    </section>

</div>
