import {Component} from '@angular/core';
import {environment} from '../../../environments/environment';
import {UserInformationService} from '../../services/user-information.service';

@Component({
    selector: 'vi-no-systems-found',
    templateUrl: './no-systems-found.component.html',
    styleUrls: ['./no-systems-found.component.scss']
})
export class NoSystemsFoundComponent {

    heatingSystemRegistrationBaseUrl = environment.heatingSystemRegistrationBase;
    viGuideBaseUrl = environment.viGuideBaseUrl;

    constructor(public userInformationService: UserInformationService) {
    }
}
