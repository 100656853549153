import {Component, OnInit, OnDestroy, Input, Inject} from '@angular/core';
import {Partner} from '../../../models/partner.model';
import {UserInformationService} from '../../../services/user-information.service';
import {environment} from '../../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {DOCUMENT} from '@angular/common';


@Component({
    selector: 'vi-system-partner',
    templateUrl: './partner.component.html',
    styleUrls: ['./partner.component.scss']
})
export class PartnerComponent implements OnInit, OnDestroy {

    @Input() partner: Partner;

    accountMaintenanceUrl = environment.accountMaintenanceUrl;
    accountContactMaintenanceUrl = environment.accountContactMaintenanceUrl;

    constructor(public userInformationService: UserInformationService, private translateService: TranslateService,
                @Inject(DOCUMENT) private document: Document) {
    }

    ngOnInit() {
        this.accountMaintenanceUrl = this.accountMaintenanceUrl.replace(/\{redirect\}/g, this.document.location.href)
            .replace(/\{title\}/g, this.translateService.instant('application.title'));
        this.accountContactMaintenanceUrl = this.accountContactMaintenanceUrl.replace(/\{redirect\}/g, this.document.location.href)
            .replace(/\{title\}/g, this.translateService.instant('application.title'));
    }

    ngOnDestroy(): void {

    }

}
