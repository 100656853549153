import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";

const API_URL = environment.apiUrl;

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class CertificateService {

    constructor(private http: HttpClient){
    }

    public sendCertificate(type, equiNumber, eMail, language): Observable<boolean> {
        var url = API_URL + '/equi-details/send-certificate' + "?type=" + type + "&equipmentNumber=" + equiNumber + "&eMail=" + eMail + "&language=" + language;
        console.log('SystemDetailService: ' + url);

        return this.http.get<boolean>(url);
    }
}
