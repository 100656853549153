import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';

export interface DialogData {
    longtext: string[];
}


@Component({
    selector: 'app-longtext-popup',
    templateUrl: 'longtext-popup.component.html',
    styleUrls: ['./longtext-popup.component.scss']
})
export class LongtextPopupComponent {


    constructor(
        public dialogRef: MatDialogRef<LongtextPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    }

    close(): void {
        this.dialogRef.close();
    }

}
