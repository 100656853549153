<div class="custom-elevation" style="background-color: white;"
     fxLayout="column">
    <div style="place-content: flex-end" fxLayout="row wrap">
        <div class="register"
             *ngIf="this.userInformationService.registrationLink()">
            <a mat-raised-button
               href="{{heatingSystemRegistrationBaseUrl}}"
               color="primary">{{ 'buttons.registration' | translate }}</a>
        </div>
        <div class="vitoguide" *ngIf="!this.userInformationService.isEK()">
            <a mat-raised-button
               href="{{viGuideBaseUrl}}"
               target="_blank" rel="noopener noreferrer"
               color="primary">
                {{ 'buttons.toVitoguide' | translate }}
            </a>
        </div>
    </div>
    <div>
        <div fxFlex="0 0 50%">
            <img mat-card-image src="../../../assets/images/no-installation.png"
                 style="padding: 29px 27px 34px; max-width: 245px;">
        </div>
        <div class="text-div">
            {{ 'noSystems.noSystemsFoundDialog' | translate }}
        </div>
    </div>
</div>
