import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SystemDetail} from '../models/system-detail.model';
import {environment} from '../../environments/environment';
import {SerialnumberResponse} from '../models/serialnumber-response.model';

const API_URL = environment.apiUrl;



@Injectable()
export class SystemDetailService {

    constructor(
        private http: HttpClient
    ) {
    }

    public getById(id, customer): Observable<SystemDetail> {

        var url = API_URL + '/equi-details/' + id + "?customer=" + customer;
        console.log('SystemDetailService: ' + url);

        return this.http.get<SystemDetail>(url);
    }

    async checkExistsAndAllowed(id): Promise<number> {

        var url = API_URL + '/equi-details/checkExistsAndAllowed/' + id;
        console.log('SystemDetailService: ' + url);

        return this.http.get<number>(url).toPromise();
    }

    public getIoTConnection(id): Observable<number> {

        let url = API_URL + '/equi-details/' + id + '/connectedToIoTPlatform';
        console.log('SystemDetailService: ' + url);

        return this.http.get<number>(url);
    }

    getPDFDVS(id, docType, docNr, docVers, docPart, language){
        const url = API_URL + '/equi-details/' + id + "/pdfDVS?docType=" + docType + "&docNr=" + docNr + "&docVers=" + docVers + "&docPart="+ docPart +"&language=" + language;

        const httpOptions = {
            headers: {
                accept: 'application/pdf'
            },
            responseType: 'arraybuffer' as 'json'
        };

        console.log('SystemDetailService: ' + url);

        return this.http.get<any>(url, httpOptions);
    }

    getPDFGOS(id, attachment){
        const url = API_URL + '/equi-details/' + id + "/pdfGOS";

        const data = {attachment: attachment,
        language: "D"};

        const httpOptions = {
            headers: {
                accept: 'application/pdf'
            },
            responseType: 'arraybuffer' as 'json'
        };

        console.log('SystemDetailService: ' + url);

        return this.http.post<any>(url, data, httpOptions);
    }

    getOrderAttachments(id, order, language, customer){
        const url = API_URL + '/equi-details/' + id + "/getOrderAttachments?order=" + order +"&language=" + language + "&customer=" + customer;

        console.log('SystemDetailService: ' + url);

        return this.http.get<any>(url);
    }

    getContractAttachments(id, contract, language){
        const url = API_URL + '/equi-details/' + id + "/getContractAttachments?contract=" + contract +"&language=" + language;

        console.log('SystemDetailService: ' + url);

        return this.http.get<any>(url);
    }

    getOfferAttachments(id, offer, language){
        const url = API_URL + '/equi-details/' + id + "/getOfferAttachments?offer=" + offer +"&language=" + language;

        console.log('SystemDetailService: ' + url);

        return this.http.get<any>(url);
    }

    getReportAttachments(id, notif, language, customer) {
        const url = API_URL + '/equi-details/' + id + "/getReportAttachments?report=" + notif +"&language=" + language + "&customer=" + customer;

        console.log('SystemDetailService: ' + url);

        return this.http.get<any>(url);
    }

    getHeadEquipment(serialNumber, customer): Observable<SerialnumberResponse> {
        const url = API_URL + '/equi-details/serialNumberDetails/headEquipment?serialNumber=' + serialNumber +"&customer=" + customer;

        console.log('SystemDetailService: ' + url);
        return this.http.get<SerialnumberResponse>(url);
    }

    getCertificate(equipmentNumber: string, language: string) {
        const url = API_URL + '/documents/certificate?system-id=' + equipmentNumber + '&language=' + language;

        const httpOptions = {
            headers: {
                accept: 'application/pdf'
            },
            responseType: 'arraybuffer' as 'json'
        };

        console.log('SystemDetailService: ' + url);

        return this.http.get<any>(url, httpOptions);
    }

    getMaintenanceProof(equipmentNumber: string, language: string) {
        const url = API_URL + '/documents/maintenance-proof?system-id=' + equipmentNumber + '&language=' + language;

        const httpOptions = {
            headers: {
                accept: 'application/pdf'
            },
            responseType: 'arraybuffer' as 'json'
        };

        console.log('SystemDetailService: ' + url);

        return this.http.get<any>(url, httpOptions);
    }
}
