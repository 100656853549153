<div class="custom-elevation" flex="row">
    <div fxFlex="0 0 50%">
        <img mat-card-image src="../../../assets/images/no-installation.png"
             style="padding: 29px 27px 34px; max-width: 245px;">
    </div>
    <div class="text-div">
        <div [innerHTML]="'detailsPage.sEqui' | translate">
        </div>
        <div style="padding-top: 16px">
            <a mat-raised-button
               href="{{heatingSystemRegistrationSerialNumberUrl.replace('{serialNumber}', serialNumber)}}"
               color="primary">
                {{ 'buttons.registration' | translate }}
            </a>
        </div>
        <div style="padding-top: 16px">
            <button mat-raised-button (click)="toOverview()"
               color="primary">
                {{ 'buttons.addToExistingSystem' | translate }}
            </button>
        </div>
    </div>
</div>
