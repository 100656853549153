import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {UserInformationService} from '../../services/user-information.service';
import {environment} from '../../../environments/environment';
import {Structure} from '../../models/structure.model';

@Component({
    selector: 'vi-equi-not-allowed',
    templateUrl: './equi-not-allowed.component.html',
    styleUrls: ['./equi-not-allowed.component.scss']
})
export class EquiNotAllowedComponent implements OnInit, AfterViewInit{


    constructor(private activatedRoute: ActivatedRoute, private router: Router, public dialog: MatDialog,
                public userInformationService: UserInformationService) {
    }
    heatingSystemRegistrationSerialNumberUrl = environment.heatingSystemRegistrationSerialnumberChange;
    @Input() structure: Structure;
    private subscription: Subscription;
    equipmentNumber: number;
    customerID: number;

    ngOnInit() {
    this.subscription = this.activatedRoute.params.subscribe(
    params => {
        this.equipmentNumber = +params['equinumber'];
        this.customerID = +params['customerID'];
        });
    }

    ngAfterViewInit(): void {

    }


    back(){
            this.router.navigate(['../list']);
        }
}
