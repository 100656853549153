import {Component, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {TranslationConfigService} from '../../services/translation-config.service';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '../../app.component';

const API_URL = environment.apiUrl;
@Component({
    selector: 'vi-welcome',
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit{

    iamConfig = environment.IAMConfig;
    endCustomerRegistration = environment.endCustomerRegistration;
    customerRegistration = environment.customerRegistration;

    isUSOrCA: boolean;
    country: string;

    constructor(private translate: TranslateService, private http: HttpClient,
                private translationConfig: TranslationConfigService, private configService: ConfigService){
    }

    ngOnInit(){
        this.country = this.configService.country;
        this.isUSOrCA = this.configService.country === 'CA' || this.configService.country === 'US';
        this.translate.use(this.translationConfig.getUserLanguage());
    }

    getLocation() {
        return encodeURIComponent(window.location.href);
    }
}
