import {Component, OnInit, OnDestroy, ViewChild, AfterViewInit} from '@angular/core';
import {SystemDetailService} from '../../services/system-detail.service';
import {SystemDetail} from '../../models/system-detail.model';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {UserInformationService} from '../../services/user-information.service';
import {environment} from '../../../environments/environment';
import {Location} from '@angular/common';
import {NotRegistratedPopupComponent} from '../systems-overview/not-registrated-popup/not-registrated-popup.component';
import {NotFoundPopupComponent} from '../systems-overview/not-found-popup/not-found-popup.component';
import {System} from '../../models/system-overview.model';
import {SerialnumberResponse} from '../../models/serialnumber-response.model';

@Component({
    selector: 'vi-find-by-serialnumber',
    templateUrl: './find-by-serialnumber.component.html',
    styles: []
})
export class FindBySerialnumberComponent implements OnInit, OnDestroy {

    serialNumber: string;
    response: SerialnumberResponse;
    private subscription: Subscription;
    private startSubscription: Subscription;

    constructor(private systemDetailService: SystemDetailService, private activatedRoute: ActivatedRoute,
                private router: Router, public dialog: MatDialog, public userInformationService: UserInformationService,
                private location: Location) {
        this.startSubscription = this.userInformationService.appReadyChanged$.subscribe((ready) => {
            this.handleAppStart();

        });
        this.subscription = this.activatedRoute.params.subscribe(
            async params => {
                this.serialNumber = (+params['serialnumber']).toString();
            });
    }

    async handleAppStart() {
        await this.startComponent();
    }

    ngOnInit() {
        this.userInformationService.initCurrentUser();
    }


    async startComponent() {
        this.userInformationService.serialSearch = this.serialNumber.toString();
        await this.getHeadEquipment(this.serialNumber.toString());
    }


    ngOnDestroy(): void {
        if (this.subscription != undefined) {
            this.subscription.unsubscribe();
        }
    }

    openNotRegisteredDialog(serialNumber: string): void {
        const dialogRef = this.dialog.open(NotRegistratedPopupComponent, {
            width: '480px',
            data: {
                context: 'find-by-serialnumber',
                serialNumber: serialNumber,
                system: null
            }
        });
    }

    openNotFoundDialog(serialNumber: string): void {
        const dialogRef = this.dialog.open(NotFoundPopupComponent, {
            width: '480px',
            data: {
                context: 'find-by-serialnumber',
                serialNumber: serialNumber
            }
        });
    }


    async getHeadEquipment(serialnumber: string) {
        this.systemDetailService.getHeadEquipment(serialnumber, this.userInformationService.shownCustomer).subscribe(resp => {
            this.response = resp;
            if (this.response.info === 'Serialnumber does not exist') {
                this.openNotFoundDialog(this.serialNumber);
            } else if (this.response.info === '') {
                this.openNotRegisteredDialog(this.serialNumber);
            } else {
                this.router.navigate(['../details/' + this.response.headEquipment]).then(() => {
                });
            }
            this.subscription.unsubscribe();
            this.startSubscription.unsubscribe();
        });
    }

}
