<div _ngcontent-kdx-c9="" class="landing-page-container image-wrapper" fxlayout="" fxlayoutalign="start stretch" ng-reflect-fx-layout="" ng-reflect-fx-layout-align="start stretch" style="flex-direction: row; box-sizing: border-box; display: flex; place-content: stretch flex-start; align-items: stretch; max-height: 100%;">
    <div class="landing-page-image" fxflex="" fxflexoffset.gt-sm="20%" ng-reflect-fx-flex-offset.gt-sm="20%" ng-reflect-fx-flex="" style="flex: 1 1 0%; box-sizing: border-box; margin-left: 20%;">
    </div>
</div>
<div _ngcontent-wqv-c5="" class="landing-page-container content-wrapper landscape-layout" fxlayout="row" fxlayoutalign="start center" style="flex-direction: row; box-sizing: border-box; display: flex; place-content: center flex-start; align-items: center;">
    <section _ngcontent-wqv-c5="" class="info-sections-wrapper" fxflexoffset="5%" style="margin-left: 5%;">
        <div _ngcontent-wqv-c5="" class="info-section">
            <h4 _ngcontent-wqv-c5="" class="sub-title" [innerHTML]="'notReleased.dialog' | translate"></h4>
        </div>
    </section>
</div>

