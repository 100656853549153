import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {share, tap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {Router} from '@angular/router';
import {UserInformationService} from './user-information.service';
import {CsrfTokenService} from './csrf-token.service';
import jwt_decode from 'jwt-decode';


@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private CSRF_TOKEN_STORAGE_KEY = 'fp_csrf_token';

    public csrfToken$ = new BehaviorSubject(undefined);
    private fetchNewCsrfToken$: Observable<{ token: string }>;

    private httpClient: HttpClient;

    private isNotRequired = true;


    constructor(handler: HttpBackend, private userInformationService: UserInformationService, private router: Router,
                private csrfTokenService: CsrfTokenService) {
        this.httpClient = new HttpClient(handler);
    }

    public hasValidCsrfToken(): boolean {
        const token = this.getCsrfToken();
        return token && this.isCsrfTokenValid(token);
    }

    public getCsrfToken(): string {
        return this.csrfToken$.value;
    }

    init() {
        this.csrfToken$.subscribe((jwtToken) => {
            if (jwtToken) {
                localStorage.setItem(this.CSRF_TOKEN_STORAGE_KEY, jwtToken);
            }
        });

        this.fetchNewCsrfToken$ = this.getCsrfTokenRequest().pipe(
            tap({
                next: ({ token }) => this.csrfToken$.next(token),
                error: () => this.redirectToLoginScreen(),
            }),
            share()
        );

        const token = this.getCsrfTokenFromUrl() || this.getCsrfTokenFromLocalStorage();

        if (token && this.isCsrfTokenValid(token)) {
            this.csrfToken$.next(token);
        } else {
            this.removeTokenFromLocalStorage();
            this.fetchNewCsrfToken$.subscribe();
        }
    }

    removeTokenFromLocalStorage() {
        localStorage.removeItem(this.CSRF_TOKEN_STORAGE_KEY);
    }

    fetchNewCsrfToken() {
        return this.fetchNewCsrfToken$;
    }

    public getCsrfTokenRequest() {
        console.log('NEW REQUEST');
        return this.httpClient.post<{ token: string }>(
            `${environment.IAMConfig.auth.baseUrl}auth/v1/saml/csrf`,
            {
                appId: environment.IAMConfig.auth.appId,
            },
            {
                withCredentials: true,
            }
        );
    }

    public getCsrfTokenFromLocalStorage(): string | undefined {
        return localStorage.getItem(this.CSRF_TOKEN_STORAGE_KEY);
    }

    public getCsrfTokenFromUrl(): string | void {
        const hash = window.location.hash;
        if (hash?.startsWith('#token=')) {
            const value = new HttpParams({ fromString: hash }).get('#token');
            window.location.hash = ''; // remove token form url
            return value || undefined;
        }
    }

    private redirectToLoginScreen() {
        const token = this.getCsrfTokenFromLocalStorage();
        if (token || window.location.href.indexOf('details') > 0) {
            window.location.href = `${environment.IAMConfig.auth.baseUrl}saml/sso/request?appId=${
                environment.IAMConfig.auth.appId
            }&redirectUrl=${encodeURIComponent(window.location.href)}`;
        } else {
            this.router.navigate(['/welcome']);
        }
    }

    private isCsrfTokenValid(token: string) {
        try {
            return !this.isCSRFTokenExpired(token);
        } catch (_) {
            return false;
        }
    }

    isCSRFTokenExpired(token: string): boolean {
        const date = this.getTokenExpirationDate(token);
        if (!date) {
            return false;
        }
        return date.valueOf() <= new Date().valueOf();
    }

    getTokenExpirationDate(token: string): Date | null {
        const decoded = <{ exp?: number }>jwt_decode(token);

        if (decoded.exp === undefined) {
            return null;
        }

        const date = new Date(0);
        date.setUTCSeconds(decoded.exp);
        return date;
    }

}
