<div fxLayout="column" fxLayoutAlign="center center">

    <div *ngIf="isLoading">
        <progress-spinner></progress-spinner>
    </div>

    <div *ngIf="!isLoading">

        <section class="section" fxFlex>
            <div fxLayout="column">
                <div style="margin: 16px auto auto; padding: 0 1rem; max-width: 1440px; ">
                    <mat-card class="custom-elevation">
                        <div fxLayout="row wrap">
                            <img src="  assets/images/maintenance.svg" style="width:25%"/>
                            <div style="width: 50%">
                                <h1>{{ 'COMMON.MAINTENANCE.TITLE.' + type | translate }}</h1>
                                <p [innerHTML]="'COMMON.MAINTENANCE.DESCRIPTION.' + type | translate"></p>
                                <div *ngIf="next_maintenance">
                                    <div>{{'COMMON.MAINTENANCE.MESSAGE.PLANNED' | translate: {from:next_maintenance.start_date | date : 'medium', until:next_maintenance.end_date | date : 'medium'} }}</div>
                                </div>
                                <a routerLink="/" mat-raised-button color="primary">{{ 'COMMON.MAINTENANCE.BUTTON.TEXT' | translate }}</a>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>
        </section>


    </div>
</div>
